"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/appadmin/index");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'newActivityControl',
  data: function data() {
    return {
      globalInfo: null,
      upload1: {
        del_name: ''
      },
      upload2: {
        del_name: ''
      },
      timer: null
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;

    this.getGlobalSetting();
    this.timer = setInterval(function () {
      (0, _index.fetchUpdate)().then(function (r) {
        if (!r.DATA) {
          _this.getGlobalSetting();
        }
      });
    }, 2000);
  },
  filters: {},
  methods: {
    getGlobalSetting: function getGlobalSetting() {
      var _this2 = this;

      (0, _index.getGlobalSetting)().then(function (r) {
        _this2.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null;
        _this2.upload1.del_name = _this2.globalInfo.activityControl.activity.newJumpImage1; // this.globalInfo.activityControl.directJump = [{
        //     linkId: '',
        //     activityUrl: ''
        // }]
      });
    },

    /**
     * 指定链接跳转指定网址的方法
     */
    // 添加指定链接
    addLinkId: function addLinkId() {
      this.globalInfo.activityControl.directJump.push({
        linkId: "",
        activityUrl: ""
      });
    },
    // 删除活动页链接
    delLinkId: function delLinkId(index) {
      this.globalInfo.activityControl.directJump.splice(index, 1);
    },
    // 点击率配置过滤未填写完全的配置
    filterNullLinkId: function filterNullLinkId() {
      this.globalInfo.activityControl.directJump = this.globalInfo.activityControl.directJump.filter(function (oo) {
        return oo.linkId && oo.activityUrl;
      });
    },
    updateConfig: function updateConfig() {
      var _this3 = this;

      this.filterNullLinkId();
      (0, _index.setGlobalSetting)({
        jsonData: escape(JSON.stringify(this.globalInfo))
      }).then(function (r) {
        _this3.getGlobalSetting();

        (0, _elementUi.Message)({
          type: "success",
          message: "操作成功"
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  }
};
exports.default = _default;