"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.fetchAdd = fetchAdd;
exports.fetchUpdate = fetchUpdate;
exports.fetchOff = fetchOff;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取自定义字段列表
function fetchList(data) {
  return (0, _request.default)({
    url: '/pkginfo/lists',
    method: 'post',
    data: data
  });
} // 添加自定义事件


function fetchAdd(data) {
  return (0, _request.default)({
    url: '/pkginfo/add',
    method: 'post',
    data: data
  });
} // 添加自定义事件


function fetchUpdate(data) {
  return (0, _request.default)({
    url: '/pkginfo/update',
    method: 'post',
    data: data
  });
} // 开关


function fetchOff(data) {
  return (0, _request.default)({
    url: '/pkginfo/off',
    method: 'post',
    data: data
  });
}