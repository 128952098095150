"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.download = download;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取投诉列表
function fetchList(data) {
  return (0, _request.default)({
    url: '/Feedback/lists',
    method: 'post',
    data: data
  });
} // 投诉列表导出


function download(data) {
  return (0, _request.default)({
    url: '/Feedback/explists',
    method: 'post',
    data: data
  });
}