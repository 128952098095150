"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ltList = ltList;
exports.ltAdd = ltAdd;
exports.ltUpdate = ltUpdate;
exports.ltDel = ltDel;
exports.ltDaili = ltDaili;
exports.getAppList = getAppList;
exports.getPlatform = getPlatform;

var _request = _interopRequireDefault(require("@/utils/request"));

// 列表
function ltList(data) {
  return (0, _request.default)({
    url: '/lt/lists',
    method: 'post',
    data: data
  });
} // 新增


function ltAdd(data) {
  return (0, _request.default)({
    url: '/lt/add',
    method: 'post',
    data: data
  });
} // 编辑


function ltUpdate(data) {
  return (0, _request.default)({
    url: '/lt/update',
    method: 'post',
    data: data
  });
} // 删除


function ltDel(data) {
  return (0, _request.default)({
    url: '/lt/del',
    method: 'post',
    data: data
  });
} //代理商列表


function ltDaili() {
  return (0, _request.default)({
    url: '/lt/daili',
    method: 'post'
  });
}

function getAppList() {
  return (0, _request.default)({
    url: '/pkginfo/applist',
    method: 'post'
  });
} // 获取联调的平台


function getPlatform(data) {
  return (0, _request.default)({
    url: '/Lt/platform',
    method: 'post',
    data: data
  });
}