"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _customEvents = require("@/api/advEvent/customEvents");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formLabelWidth: "140px",
      dialogFormVisible: false,
      formInfo: {
        field: "",
        name: "",
        event_id: ""
      },
      expand: ['manufacturer'],
      listData: [],
      rules: {
        name: [{
          required: true,
          message: '事件名称是必需填写的',
          trigger: 'blur'
        }],
        event_id: [{
          required: true,
          message: '事件ID是必需填写的',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _customEvents.fetchList)().then(function (response) {
        if (response.STATUS == 2000) {
          _this.listData = response.DATA;
        } else {
          (0, _elementUi.Message)({
            message: '获取字段列表失败，请刷新',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    showAdd: function showAdd(row) {
      this.formInfo.field = row.field;
      this.formInfo.name = '';
      this.formInfo.event_id = '';
      this.dialogFormVisible = true;
    },
    eventAddConfirm: function eventAddConfirm() {
      var _this2 = this;

      this.$refs['dataForm1'].validate(function (valid) {
        if (valid) {
          (0, _customEvents.fetchAdd)(_this2.formInfo).then(function (response) {
            if (response.STATUS == 2000) {
              _this2.getList();

              _this2.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: '添加成功',
                type: 'success',
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: '添加失败',
                type: 'error',
                duration: 2000
              });
            }
          });
        }
      });
    },
    confirmDel: function confirmDel(row, item) {
      var _this3 = this;

      _elementUi.MessageBox.confirm('确定要删除吗？', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var obj = {
          field: row.field,
          name: item.name,
          event_id: item.event_id
        };
        (0, _customEvents.fetchDel)(obj).then(function (response) {
          if (response.STATUS == 2000) {
            _this3.getList();

            (0, _elementUi.Message)({
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
          } else {
            (0, _elementUi.Message)({
              message: '删除失败',
              type: 'error',
              duration: 2000
            });
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;