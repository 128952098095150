"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;
exports.getRole = getRole;
exports.setRole = setRole;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'admin_token';
var RoleKey = 'role_token';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getRole() {
  return _jsCookie.default.get(RoleKey);
}

function setRole(role) {
  return _jsCookie.default.set(RoleKey, role);
}