var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "div",
            [
              _c(
                "span",
                { staticStyle: { "font-size": "14px", color: "#409eff" } },
                [_vm._v("当前应用:")]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { height: "20px" },
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.onChangeApp },
                  model: {
                    value: _vm.curAppName,
                    callback: function($$v) {
                      _vm.curAppName = $$v
                    },
                    expression: "curAppName"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar ? _vm.avatar : _vm.src }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("首页")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出登录")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }