"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.fetchAdd = fetchAdd;
exports.fetchDel = fetchDel;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取自定义字段列表
function fetchList() {
  return (0, _request.default)({
    url: '/channel/configlists',
    method: 'post'
  });
} // 添加自定义事件


function fetchAdd(data) {
  return (0, _request.default)({
    url: '/channel/configadd',
    method: 'post',
    data: data
  });
} // 添加自定义事件


function fetchDel(data) {
  return (0, _request.default)({
    url: '/channel/configdel',
    method: 'post',
    data: data
  });
}