var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              on: { change: _vm.selectHandleFilter },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            [
              _c("el-option", {
                key: 0,
                attrs: { label: "按版本号查看", value: "0" }
              }),
              _vm._v(" "),
              _c("el-option", {
                key: 1,
                attrs: { label: "按迭代内容查看", value: "1" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.listQuery.type == 0
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入版本号进行搜索" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.keyWord,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "keyWord", $$v)
                  },
                  expression: "listQuery.keyWord"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.listQuery.type == 1
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入迭代内容进行搜索" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.keyWord,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "keyWord", $$v)
                  },
                  expression: "listQuery.keyWord"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("\n        搜索\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item btn",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.handleCancel }
            },
            [_vm._v("\n        重置\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin_bottom btn",
              staticStyle: { float: "right" },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.createShow }
            },
            [_vm._v("\n\t\t\t\t添加版本\n\t\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "版本号", width: "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.num))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "提审时间", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(row.update_time) }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "VIVO过审时间", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.info.vivo && row.info.vivo.status == 2
                      ? _c(
                          "div",
                          [
                            row.info.vivo &&
                            row.info.vivo.memberCheckRpkDate.length < 30
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      row.info.vivo &&
                                        row.info.vivo.memberCheckRpkDate &&
                                        _vm.dateFormat(
                                          row.info.vivo.memberCheckRpkDate
                                        )
                                    )
                                  }
                                })
                              : _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        row.info.vivo &&
                                        _vm.dateFormat(
                                          row.info.vivo.memberCheckRpkDate
                                        ),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "overflow3",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          row.info.vivo &&
                                            row.info.vivo.memberCheckRpkDate
                                        )
                                      }
                                    })
                                  ]
                                )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "OPPO过审时间", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.info.OPPO && row.info.OPPO.status == 2
                      ? _c(
                          "div",
                          [
                            row.info.OPPO &&
                            row.info.OPPO.memberCheckRpkDate.length < 30
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      row.info.OPPO &&
                                        row.info.OPPO.memberCheckRpkDate &&
                                        _vm.dateFormat(
                                          row.info.OPPO.memberCheckRpkDate
                                        )
                                    )
                                  }
                                })
                              : _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        row.info.OPPO &&
                                        _vm.dateFormat(
                                          row.info.OPPO.memberCheckRpkDate
                                        ),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "overflow3",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          row.info.OPPO &&
                                            row.info.OPPO.memberCheckRpkDate
                                        )
                                      }
                                    })
                                  ]
                                )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "小米过审时间", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.info.小米 && row.info.小米.status == 2
                      ? _c(
                          "div",
                          [
                            row.info.小米 &&
                            row.info.小米.memberCheckRpkDate.length < 30
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      row.info.小米 &&
                                        row.info.小米.status == 2 &&
                                        row.info.小米.memberCheckRpkDate &&
                                        _vm.dateFormat(
                                          row.info.小米.memberCheckRpkDate
                                        )
                                    )
                                  }
                                })
                              : _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        row.info.小米 &&
                                        _vm.dateFormat(
                                          row.info.小米.memberCheckRpkDate
                                        ),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "overflow3",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          row.info.小米 &&
                                            row.info.小米.memberCheckRpkDate
                                        )
                                      }
                                    })
                                  ]
                                )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "华为过审时间", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.info.华为 && row.info.华为.status == 2
                      ? _c(
                          "div",
                          [
                            row.info.华为 &&
                            row.info.华为.memberCheckRpkDate.length < 30
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      row.info.华为 &&
                                        row.info.华为.memberCheckRpkDate &&
                                        _vm.dateFormat(
                                          row.info.华为.memberCheckRpkDate
                                        )
                                    )
                                  }
                                })
                              : _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        row.info.华为 &&
                                        _vm.dateFormat(
                                          row.info.华为.memberCheckRpkDate
                                        ),
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "overflow3",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          row.info.华为 &&
                                            row.info.华为.memberCheckRpkDate
                                        )
                                      }
                                    })
                                  ]
                                )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    row.info.华为 && row.info.华为.status == 4
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.info.华为 && row.info.华为.reason) +
                                "\n            "
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "迭代内容", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "inline-block" } }, [
                      _c(
                        "pre",
                        {
                          staticStyle: {
                            "text-align": "left",
                            "white-space": "pre-wrap"
                          }
                        },
                        [_vm._v(_vm._s(row.up_info))]
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-around"
                        }
                      },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          on: {
                            change: function($event) {
                              return _vm.switchHandel($event, row)
                            }
                          },
                          model: {
                            value: row.is_bj,
                            callback: function($$v) {
                              _vm.$set(row, "is_bj", $$v)
                            },
                            expression: "row.is_bj"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.updateShow(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageShow",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageHidden",
        attrs: {
          layout: "prev, pager, next",
          small: true,
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: _vm.dialogWidth
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticClass: "fullwidth",
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "num" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入提交审核的版本号" },
                    model: {
                      value: _vm.temp.num,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "num", $$v)
                      },
                      expression: "temp.num"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "迭代内容", prop: "up_info" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 4, maxRows: 8 },
                      resize: "none",
                      type: "textarea",
                      placeholder: "请输入此次版本的迭代内容"
                    },
                    model: {
                      value: _vm.temp.up_info,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "up_info", $$v)
                      },
                      expression: "temp.up_info"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              (_vm.dialogStatus === "create"
              ? false
              : true)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "vivo过审时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd hh:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.temp.vivo_memberCheckRpkDate,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "vivo_memberCheckRpkDate", $$v)
                          },
                          expression: "temp.vivo_memberCheckRpkDate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.dialogStatus === "create"
              ? false
              : true)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "oppo过审时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.temp.oppo_memberCheckRpkDate,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "oppo_memberCheckRpkDate", $$v)
                          },
                          expression: "temp.oppo_memberCheckRpkDate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.dialogStatus === "create"
              ? false
              : true)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "小米过审时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.temp.xiaomi_memberCheckRpkDate,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "xiaomi_memberCheckRpkDate", $$v)
                          },
                          expression: "temp.xiaomi_memberCheckRpkDate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.dialogStatus === "create"
              ? false
              : true)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "华为过审时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.temp.huawei_memberCheckRpkDate,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "huawei_memberCheckRpkDate", $$v)
                          },
                          expression: "temp.huawei_memberCheckRpkDate"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v("\n            取消\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.postCreate()
                            : _vm.postUpdate()
                        }
                      }
                    },
                    [_vm._v("\n            确定\n          ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }