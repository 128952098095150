var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.globalInfo
        ? _c(
            "div",
            { staticClass: "search-box" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.updateConfig()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("屏蔽地区用户显示投诉入口")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.shieldFeedbackShow,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting,
                                "shieldFeedbackShow",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.shieldFeedbackShow"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("开启时如果用户是屏蔽地区也会显示投诉入口")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("投诉过的用户是否直接退出")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.feedbackUserExit,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting,
                                "feedbackUserExit",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.feedbackUserExit"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("开启时如果用户有投诉过则提示本产品终止服务并退出")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("赚钱模块新用户是否展示新人红包")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.zhuanqianRedPaper,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting,
                                "zhuanqianRedPaper",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.zhuanqianRedPaper"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("开启时如果用户是新用户的话会出现新人红包")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("是否开启插屏广告位")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.interstitialSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting,
                                "interstitialSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.interstitialSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "触发场景：当活动页返回应用首页时，广告来自厂商广告。"
                      )
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("用户离开快应用，是否开启提醒广告位:")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet.switch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "switch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.switch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "提醒广告位，小米跟华为只执行APP广告和WEB广告，OPPO、VIVO 厂商广告优先"
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("用户离开H5活动页，是否开启提醒广告位(其他厂商):")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .activitySwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "activitySwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.activitySwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "提醒广告位，OPPO、VIVO 等厂商原生广告优先，然后再APP和WEB广告"
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("用户离开H5活动页，是否开启提醒广告位(小米):")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .xiaomiActivitySwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "xiaomiActivitySwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.xiaomiActivitySwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "提醒广告位，小米厂商原生广告优先，然后再APP和WEB广告"
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("用户离开H5活动页，是否开启提醒广告位(华为):")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .huaweiActivitySwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "huaweiActivitySwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.huaweiActivitySwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "提醒广告位，华为厂商原生广告优先，然后再APP和WEB广告"
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v(
                        "用户离开快应用，符合条件时提醒广告位显示查看按钮:"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .bottomSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "bottomSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.bottomSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("条件：在“黑名单”内的就触发")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v("用户离开快应用，符合条件时是否引导到其他应用:")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .deeplinkSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "deeplinkSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.deeplinkSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("条件：不在“黑名单”内的就触发")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("应用名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入应用名称" },
                            model: {
                              value:
                                _vm.globalInfo.businessSetting.leaveSet
                                  .deeplinkName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.businessSetting.leaveSet,
                                  "deeplinkName",
                                  $$v
                                )
                              },
                              expression:
                                "globalInfo.businessSetting.leaveSet.deeplinkName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("填写将被拉起的快应用名称")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("deeplink链接")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入要跳转的deeplink链接"
                            },
                            model: {
                              value:
                                _vm.globalInfo.businessSetting.leaveSet
                                  .deeplink,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.businessSetting.leaveSet,
                                  "deeplink",
                                  $$v
                                )
                              },
                              expression:
                                "globalInfo.businessSetting.leaveSet.deeplink"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "举例：hap://app/cloud.quick.constellatory/Page/Main?qd_id=hap&link_id=hap"
                      )
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v(
                        "黑名单地区，从首页点击icon图标，进入跳转到指定url地址"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.businessSetting.leaveSet
                                .activityEnterUrlSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.businessSetting.leaveSet,
                                "activityEnterUrlSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.businessSetting.leaveSet.activityEnterUrlSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("条件：在“黑名单”内的就触发")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "40px" } }, [
                _c("div", { staticClass: "my_describ_tow_line" }, [
                  _vm._v("触发场景：用户点击icon时")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("链接名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入链接名称" },
                            model: {
                              value:
                                _vm.globalInfo.businessSetting.leaveSet
                                  .activityEnterName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.businessSetting.leaveSet,
                                  "activityEnterName",
                                  $$v
                                )
                              },
                              expression:
                                "globalInfo.businessSetting.leaveSet.activityEnterName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("给链接起个名称，作为向服务器上报的标识")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("活动页链接")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入活动页链接" },
                            model: {
                              value:
                                _vm.globalInfo.businessSetting.leaveSet
                                  .activityEnterUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.businessSetting.leaveSet,
                                  "activityEnterUrl",
                                  $$v
                                )
                              },
                              expression:
                                "globalInfo.businessSetting.leaveSet.activityEnterUrl"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("去“推啊”或者其他互动广告平台获取互动广告推广链接")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "my_label" }, [
                      _vm._v(
                        "当用户用完抽奖机会时，点击活动图标是否跳转到其他快应用"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.globalInfo.activityControl.finishSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.activityControl,
                                "finishSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.activityControl.finishSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("条件：不在“黑名单”内的就触发")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("应用名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入要跳转的应用名称" },
                            model: {
                              value: _vm.globalInfo.activityControl.finishName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.activityControl,
                                  "finishName",
                                  $$v
                                )
                              },
                              expression:
                                "globalInfo.activityControl.finishName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("填写将被拉起的快应用名称")
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("跳转地址")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入要跳转的deeplink链接"
                            },
                            model: {
                              value: _vm.globalInfo.activityControl.finishUrl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.activityControl,
                                  "finishUrl",
                                  $$v
                                )
                              },
                              expression: "globalInfo.activityControl.finishUrl"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v(
                        "举例：hap://app/cloud.quick.constellatory/Page/Main?qd_id=hap&link_id=hap"
                      )
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }