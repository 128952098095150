"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _list = require("@/api/feedback/list");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: 'List',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      value1: [(0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}'), (0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}')],
      listQuery: {
        page: 1,
        limit: 20,
        link_id: '',
        pkg_id: '',
        referer: '',
        type: 3,
        start_tm: '',
        end_tm: '',
        qudao: ''
      },
      qudao: [],
      pkg: [],
      pickerOptions2: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime());
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, start]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      clickDownload: true,
      clickDownloadIcon: 'el-icon-download'
    };
  },
  created: function created() {
    this.getList();
  },
  filters: {
    timerFilter: function timerFilter(str) {
      return (0, _utils.parseTime)(str, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    typeFilter: function typeFilter(str) {
      if (str == 1) {
        return '快应用';
      } else if (str == 2) {
        return '落地页';
      } else if (str == 3) {
        return '落地页';
      } else {
        return '赚钱页';
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      if (this.value1) {
        this.listQuery.start_tm = this.value1[0];
        this.listQuery.end_tm = this.value1[1];
      } else {
        this.listQuery.start_tm = '';
        this.listQuery.end_tm = '';
      }

      var obj = {
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        referer: this.listQuery.referer,
        qudao: this.listQuery.qudao,
        type: this.listQuery.type,
        link_id: this.listQuery.link_id,
        pkg_id: this.listQuery.pkg_id,
        start_tm: this.listQuery.start_tm,
        end_tm: this.listQuery.end_tm
      };
      (0, _list.fetchList)(obj).then(function (response) {
        _this.listLoading = false;

        if (response.STATUS == 2000) {
          _this.list = response.DATA.items;
          _this.total = response.DATA.totalSize;
          _this.qudao = response.QUDAO;
          _this.pkg = response.PKG;
        } else {
          (0, _elementUi.Message)({
            message: response.DEC || '获取投诉列表失败',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCancel: function handleCancel() {
      this.listQuery.page = 1;
      this.listQuery.link_id = '';
      this.listQuery.pkg_id = '';
      this.listQuery.referer = '';
      this.listQuery.qudao = '';
      this.value1 = [(0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}'), (0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}')];
      this.getList();
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      if (this.clickDownload) {
        this.clickDownload = false;
        this.clickDownloadIcon = 'el-icon-loading';
        var obj = {
          totalSize: this.total,
          limit: 1000,
          start_tm: this.value1[0],
          end_tm: this.value1[1],
          pkg_id: this.listQuery.pkg_id,
          referer: this.listQuery.referer,
          qudao: this.listQuery.qudao,
          link_id: this.listQuery.link_id,
          type: this.listQuery.type
        };
        (0, _list.download)(obj).then(function (response) {
          _this2.clickDownload = true;
          _this2.clickDownloadIcon = 'el-icon-download';

          if (response.STATUS == 2000) {
            window.open(response.DATA, '_blank');
          } else {
            (0, _elementUi.Message)({
              message: response.DEC || '导出表格失败',
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    }
  }
};
exports.default = _default;