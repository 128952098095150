"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGlobalSetting = getGlobalSetting;
exports.setGlobalSetting = setGlobalSetting;
exports.fetchUpdate = fetchUpdate;
exports.getGlobalSettingQudao = getGlobalSettingQudao;
exports.setGlobalSettingQudao = setGlobalSettingQudao;
exports.fetchUpdateQudao = fetchUpdateQudao;
exports.setSafeDownload = setSafeDownload;
exports.getSafeDownload = getSafeDownload;
exports.setJsfBeforRunWeb = setJsfBeforRunWeb;
exports.getJsfBeforRunWeb = getJsfBeforRunWeb;
exports.getAndroidSetting = getAndroidSetting;
exports.setAndroidSetting = setAndroidSetting;
exports.getNavigationList = getNavigationList;
exports.navigationEdit = navigationEdit;
exports.navigationAdd = navigationAdd;
exports.getSearchList = getSearchList;
exports.searchEdit = searchEdit;
exports.searchAdd = searchAdd;
exports.blackCheck = blackCheck;
exports.getWhiteList = getWhiteList;
exports.addWhiteList = addWhiteList;
exports.delWhiteList = delWhiteList;
exports.getBalckList = getBalckList;
exports.addBalckItem = addBalckItem;
exports.delBlackItem = delBlackItem;
exports.getUserList = getUserList;
exports.delFaceback = delFaceback;
exports.getFeedbackList = getFeedbackList;
exports.confirmFeedBack = confirmFeedBack;
exports.getTestUrl = getTestUrl;
exports.setTestUrl = setTestUrl;
exports.getAdinfoList = getAdinfoList;
exports.addAdinfoInfo = addAdinfoInfo;
exports.editAdinfoInfo = editAdinfoInfo;
exports.delAdinfoInfo = delAdinfoInfo;
exports.getOpenScreenAdvList = getOpenScreenAdvList;
exports.addOpenScreenAdv = addOpenScreenAdv;
exports.editOpenScreenAdv = editOpenScreenAdv;
exports.delOpenScreenAdv = delOpenScreenAdv;

var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.regexp.to-string");

var _request = _interopRequireDefault(require("@/utils/request"));

var _elementUi = _interopRequireDefault(require("element-ui"));

var _store = _interopRequireDefault(require("@/store"));

// function encrypt(code){  
//     var c=String.fromCharCode(code.charCodeAt(0)+code.length);
//      for(var i=1;i<code.length;i++)
//     {
//     c+=String.fromCharCode(code.charCodeAt(i)+code.charCodeAt(i-1));
//      }
//     return escape(c); 
// }
var pwd = "cloud";

function encrypt(str, pwd) {
  if (pwd == null || pwd.length <= 0) {
    alert("Please enter a password with which to encrypt the message.");
    return null;
  }

  var prand = "";

  for (var i = 0; i < pwd.length; i++) {
    prand += pwd.charCodeAt(i).toString();
  }

  var sPos = Math.floor(prand.length / 5);
  var mult = parseInt(prand.charAt(sPos) + prand.charAt(sPos * 2) + prand.charAt(sPos * 3) + prand.charAt(sPos * 4) + prand.charAt(sPos * 5));
  var incr = Math.ceil(pwd.length / 2);
  var modu = Math.pow(2, 31) - 1;

  if (mult < 2) {
    alert("Algorithm cannot find a suitable hash. Please choose a different password.  Possible considerations are to choose a more complex or longer password.");
    return null;
  }

  var salt = Math.round(Math.random() * 1000000000) % 100000000;
  prand += salt;

  while (prand.length > 10) {
    prand = (parseInt(prand.substring(0, 10)) + parseInt(prand.substring(10, prand.length))).toString();
  }

  prand = (mult * prand + incr) % modu;
  var enc_chr = "";
  var enc_str = "";

  for (var i = 0; i < str.length; i++) {
    enc_chr = parseInt(str.charCodeAt(i) ^ Math.floor(prand / modu * 255));

    if (enc_chr < 16) {
      enc_str += "0" + enc_chr.toString(16);
    } else {
      enc_str += enc_chr.toString(16);
    }

    prand = (mult * prand + incr) % modu;
  }

  salt = salt.toString(16);

  while (salt.length < 8) {
    salt = "0" + salt;
  }

  enc_str += salt;
  return enc_str;
} // 获取全局配置信息


function getGlobalSetting(data) {
  return (0, _request.default)({
    url: '/guide/lists',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name
    })
  });
} // 设置全局配置信息


function setGlobalSetting(data) {
  return (0, _request.default)({
    url: '/guide/sets',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name,
      jsonDataDes: encrypt(data.jsonData, pwd)
    })
  });
} // 设置全局配置信息


function fetchUpdate() {
  return (0, _request.default)({
    url: '/guide/index',
    method: 'post',
    data: {
      pkg: _store.default.getters.cur_app_name
    }
  });
} // 获取指定渠道信息


function getGlobalSettingQudao(pkg) {
  return (0, _request.default)({
    url: '/guide/lists',
    method: 'post',
    data: {
      pkg: pkg
    }
  });
} // 设置指定渠道信息


function setGlobalSettingQudao(pkg, data) {
  return (0, _request.default)({
    url: '/guide/sets',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: pkg,
      jsonDataDes: encrypt(data.jsonData, pwd)
    })
  });
} // 获取指定渠道更新信息


function fetchUpdateQudao(pkg) {
  return (0, _request.default)({
    url: '/guide/index',
    method: 'post',
    data: {
      pkg: pkg
    }
  });
} // 设置安全下载数据


function setSafeDownload(data) {
  return (0, _request.default)({
    url: '/guide/setsSafeDownload',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name,
      jsonDataDes: encrypt(data.jsonData, pwd)
    })
  });
} // 获取安全下载数据


function getSafeDownload(data) {
  return (0, _request.default)({
    url: '/guide/listsSafeDownload',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name
    })
  });
} // 设置请求前需要执行的js


function setJsfBeforRunWeb(data) {
  return (0, _request.default)({
    url: '/guide/setjs',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name,
      jsonDataDes: encrypt(data.jsonData, pwd)
    })
  });
} // 获取请求前需要执行的js


function getJsfBeforRunWeb(data) {
  return (0, _request.default)({
    url: '/guide/listssetjs',
    method: 'post',
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      pkg: _store.default.getters.cur_app_name
    })
  });
} // 安卓获取全局配置信息


function getAndroidSetting(data) {
  return (0, _request.default)({
    url: '/guide/androidlists',
    method: 'post',
    data: data
  });
} // 安卓设置全局配置信息


function setAndroidSetting(data) {
  return (0, _request.default)({
    url: '/guide/androidsets',
    method: 'post',
    data: data
  });
} // //获取浏览器导航列表


function getNavigationList(data) {
  return (0, _request.default)({
    url: '/navigation/lists',
    method: 'post',
    data: data
  });
} // //编辑浏览器导航


function navigationEdit(data) {
  return (0, _request.default)({
    url: '/navigation/edit',
    method: 'post',
    data: data
  });
} // //新增浏览器导航


function navigationAdd(data) {
  return (0, _request.default)({
    url: '/navigation/add',
    method: 'post',
    data: data
  });
} // //获取搜索引擎列表


function getSearchList(data) {
  return (0, _request.default)({
    url: '/search/lists',
    method: 'post',
    data: data
  });
} // //编辑搜索引擎


function searchEdit(data) {
  return (0, _request.default)({
    url: '/search/edit',
    method: 'post',
    data: data
  });
} //新增搜索引擎


function searchAdd(data) {
  return (0, _request.default)({
    url: '/search/add',
    method: 'post',
    data: data
  });
} //检测是否已经在黑名单中存在


function blackCheck(data) {
  return (0, _request.default)({
    url: '/black/check',
    method: 'post',
    data: data
  });
} // //获取小说白名单列表


function getWhiteList(data) {
  return (0, _request.default)({
    url: '/novel/lists',
    method: 'post',
    data: data
  });
} // //添加小说白名单


function addWhiteList(data) {
  return (0, _request.default)({
    url: '/novel/add',
    method: 'post',
    data: data
  });
} // //删除小说白名单


function delWhiteList(data) {
  return (0, _request.default)({
    url: '/novel/del',
    method: 'post',
    data: data
  });
} //获取小说黑名单


function getBalckList(data) {
  return (0, _request.default)({
    url: '/black/lists',
    method: 'post',
    data: data
  });
} //新增小说黑名单


function addBalckItem(data) {
  return (0, _request.default)({
    url: '/black/add',
    method: 'post',
    data: data
  });
} //删除小说黑名单


function delBlackItem(data) {
  return (0, _request.default)({
    url: '/black/del',
    method: 'post',
    data: data
  });
} // export function getSettleList(data) {
//     return request({
//         url: '/settlement/lists',
//         method: 'post',
//         data
//     })
// }
// //获取结算列表
// export function getSettleList(data) {
//     return request({
//         url: '/settlement/lists',
//         method: 'post',
//         data
//     })
// }
// //新增结算数据
// export function addSettleList(data) {
//     return request({
//         url: '/settlement/add',
//         method: 'post',
//         data
//     })
// }
// //删除结算数据
// export function delSettleList(data) {
//     return request({
//         url: '/settlement/del',
//         method: 'post',
//         data
//     })
// }
// //修改结算数据
// export function editSettleList(data) {
//     return request({
//         url: '/settlement/edit',
//         method: 'post',
//         data
//     })
// }
// //获取媒体渠道列表
// export function getMediaList(data) {
//     return request({
//         url: '/media/lists',
//         method: 'post',
//         data
//     })
// }
// //新增媒体渠道数据
// export function addMediaList(data) {
//     return request({
//         url: '/media/add',
//         method: 'post',
//         data
//     })
// }
// //删除媒体渠道数据
// export function delMediaList(data) {
//     return request({
//         url: '/media/del',
//         method: 'post',
//         data
//     })
// }
// //修改媒体渠道数据
// export function editMediaList(data) {
//     return request({
//         url: '/media/edit',
//         method: 'post',
//         data
//     })
// }
//获取用户列表


function getUserList(data) {
  return (0, _request.default)({
    url: '/user/lists',
    method: 'post',
    data: data
  });
} // //获取管理员列表
// export function getAdminList(data) {
//     return request({
//         url: '/admin/lists',
//         method: 'post',
//         data
//     })
// }
// //添加管理员
// export function adminAdd(data) {
//     return request({
//         url: '/admin/add',
//         method: 'post',
//         data
//     })
// }
// //修改管理员密码
// export function adminEdit(data) {
//     return request({
//         url: '/admin/edit',
//         method: 'post',
//         data
//     })
// }
// //设置json配置
// export function setJsonData(data) {
//     return request({
//         url: '/verify/setDeskTime',
//         method: 'post',
//         data
//     })
// }
// //获取json配置
// export function getJsonData(data) {
//     return request({
//         url: '/set/deskTime',
//         method: 'post',
//         data
//     })
// }
// //获取版本信息
// export function getVersionInfo(data) {
//     return request({
//         url: '/version/info',
//         method: 'post',
//         data
//     })
// }
// //获取设置信息
// export function editSettingInfo(data) {
//     return request({
//         url: '/version/edit',
//         method: 'post',
//         data
//     })
// }
// //获取首页统计信息
// export function getCountInfo(data) {
//     return request({
//         url: '/count/info',
//         method: 'post',
//         data
//     })
// }
// //获取首页网址支持信息
// export function getHostCountInfo(data) {
//     return request({
//         url: '/count/host',
//         method: 'post',
//         data
//     })
// }
// //获取30天注册数量变化
// export function getChartReg(data) {
//     return request({
//         url: '/count/chartReg',
//         method: 'post',
//         data
//     })
// }
// //获取30天记账数量变化
// export function getChartAccountLog(data) {
//     return request({
//         url: '/count/chartAccountLog',
//         method: 'post',
//         data
//     })
// }
// //获取30天记账人数变化
// export function getChartAccountUser(data) {
//     return request({
//         url: '/count/chartAccountUser',
//         method: 'post',
//         data
//     })
// }
// //获取30天各渠道注册数
// export function getChartSourceReg(data) {
//     return request({
//         url: 'count/chartSourceReg',
//         method: 'post',
//         data
//     })
// }
// //获取详细记账数据
// export function getChartUserAccount(data) {
//     return request({
//         url: '/count/chartUserAccount',
//         method: 'post',
//         data
//     })
// }
// //获取待审核域列表
// export function getVerifyList(data) {
//     return request({
//         url: '/verify/lists',
//         method: 'post',
//         data
//     })
// }
// //确认待审核
// export function confirmVerify(data) {
//     return request({
//         url: '/verify/save',
//         method: 'post',
//         data
//     })
// }
//删除用户反馈


function delFaceback(data) {
  return (0, _request.default)({
    url: '/feedback/clear',
    method: 'post',
    data: data
  });
} //获取错误反馈列表


function getFeedbackList(data) {
  return (0, _request.default)({
    url: '/feedback/lists',
    method: 'post',
    data: data
  });
} //错误状态修改


function confirmFeedBack(data) {
  return (0, _request.default)({
    url: '/feedback/save',
    method: 'post',
    data: data
  });
} //获取测试地址


function getTestUrl(data) {
  return (0, _request.default)({
    url: '/user/get',
    method: 'post',
    data: data
  });
} //设置测试地址


function setTestUrl(data) {
  return (0, _request.default)({
    url: '/user/edit',
    method: 'post',
    data: data
  });
} //获取资讯广告列表


function getAdinfoList(data) {
  return (0, _request.default)({
    url: '/adinfo/lists',
    method: 'post',
    data: data
  });
} //新增资讯广告


function addAdinfoInfo(data) {
  return (0, _request.default)({
    url: '/adinfo/add',
    method: 'post',
    data: data
  });
} //编辑资讯广告


function editAdinfoInfo(data) {
  return (0, _request.default)({
    url: '/adinfo/edit',
    method: 'post',
    data: data
  });
} //删除资讯广告


function delAdinfoInfo(data) {
  return (0, _request.default)({
    url: '/adinfo/del',
    method: 'post',
    data: data
  });
} //获取开屏广告列表


function getOpenScreenAdvList(data) {
  return (0, _request.default)({
    url: '/Adscreen/lists',
    method: 'post',
    data: data
  });
} //新增开屏广告


function addOpenScreenAdv(data) {
  return (0, _request.default)({
    url: '/adscreen/add',
    method: 'post',
    data: data
  });
} //编辑开屏广告


function editOpenScreenAdv(data) {
  return (0, _request.default)({
    url: '/adscreen/edit',
    method: 'post',
    data: data
  });
} //删除开屏广告


function delOpenScreenAdv(data) {
  return (0, _request.default)({
    url: '/adscreen/del',
    method: 'post',
    data: data
  });
} // //获取或设置是否开启反审核开关
// export function switchInfo(data) {
//     return request({
//         url: '/browser/switch',
//         method: 'post',
//         data
//     })
// }
// //获取或设置是否开启信息流开关
// export function newsInfo(data) {
//     return request({
//         url: '/browser/news',
//         method: 'post',
//         data
//     })
// }
// //获取或设置是否开启赚钱模块开关
// export function moneyInfo(data) {
//     return request({
//         url: '/browser/money',
//         method: 'post',
//         data
//     })
// }