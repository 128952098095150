"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _qs = _interopRequireDefault(require("qs"));

var _auth = require("@/utils/auth");

var _router = _interopRequireDefault(require("@/router"));

// 创建一个错误
function errorCreat(msg) {
  var err = new Error(msg);
  errorLog(err);
  throw err;
} // 记录和显示错误


function errorLog(err) {
  // 显示提示
  (0, _elementUi.Message)({
    message: err.message,
    type: 'error',
    duration: 3 * 1000
  });
} // create an axios instance


var service = _axios.default.create({
  baseURL: process.env.NODE_ENV == 'production' ? "http://config.yunyihudong.com:1111" : 'http://config.yunyihudong.com:2222',
  // baseURL: "http://config.yunyihudong.com:2222", // 测试
  // baseURL: "http://config.yunyihudong.com:1111", // 正式
  //"http://api.myquxin.com", //process.env.VUE_APP_BASE_API //测试地址 apimanagedev  正式地址 apimanage
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // 在请求发送之前做一些处理
  if (!/^https:\/\/|http:\/\//.test(config.url)) {
    config.headers = {
      'X-API-TOKEN': (0, _auth.getToken)(),
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  }

  config.data = _qs.default.stringify(config.data);
  return config; // // do something before request is sent
  // if (store.getters.token) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation
  //   config.headers['token'] = getToken()
  // } 
  // return config
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  // console.log(response)
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data; // 这个状态码是和后端约定的

  var code = dataAxios.STATUS; // const APITOKEN_DL = dataAxios.APITOKEN_DL 

  var APITOKEN_DL = response.headers['x-api-token'];

  if (APITOKEN_DL !== 'none') {
    // console.log(APITOKEN_DL)
    // util.cookies.set('APITOKEN_DL', APITOKEN_DL)
    (0, _auth.setToken)(APITOKEN_DL);
  } else if (APITOKEN_DL === 'none') {// console.log(APITOKEN_DL)
  } // 根据 code 进行判断


  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      case '2000':
        // [ 示例 ] code === 0 代表没有错误
        return dataAxios;

      case '50000':
        // token找不到有效的用户
        // 删除cookie
        // util.cookies.remove('APITOKEN_DL')
        (0, _auth.removeToken)(); // util.cookies.remove('uuid')
        // 跳转路由

        _router.default.push({
          name: 'login'
        });

        errorCreat("\u8BF7\u91CD\u65B0\u767B\u9646");
        break;

      case '50600':
        // token过期
        // 删除cookie
        // util.cookies.remove('APITOKEN_DL')
        (0, _auth.removeToken)(); // util.cookies.remove('uuid')
        // 跳转路由

        _router.default.push({
          name: 'login'
        });

        errorCreat("\u8BF7\u91CD\u65B0\u767B\u9646");
        break;

      default:
        // 不是正确的 code
        errorCreat("".concat(dataAxios.DEC));
        break;
    }
  }
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }
  }

  errorLog(error);
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;