"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.string.link");

var _appletSetting = require("@/api/baseControl/appletSetting");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var hourOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
var _default = {
  name: "verifyFile",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      dialogWidth: 0,
      textMap: {
        update: "编辑链接",
        create: "添加链接"
      },
      dialogStatus: "create",
      nowData: '',
      temp: {
        name: '',
        sort: '',
        manufacturer: [],
        block_linkid: '',
        start_tm: '',
        end_tm: '',
        is_xcx: 1,
        web_id: '',
        link: ''
      },
      dialogFormVisible: false,
      temp2: {
        id: undefined,
        manufacturer: [],
        block_linkid: '',
        start_tm: '',
        end_tm: '',
        is_xcx: 1,
        link: ''
      },
      dialogFormVisible2: false,
      index: '',
      temp3: {
        name: '',
        sort: '',
        web_id: '',
        link: ''
      },
      dialogFormVisible3: false,
      rules: {
        name: [{
          required: true,
          message: '请填写链接名称',
          trigger: 'blur'
        }],
        link: [{
          required: true,
          message: '请填写投放链接',
          trigger: 'blur'
        }],
        manufacturer: [{
          required: true,
          message: '请选择投放厂商',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '请输入链接权重',
          trigger: 'blur'
        }, {
          pattern: /^-?\d+\.?\d*$/,
          message: '链接权重必须是数字',
          trigger: 'blur'
        }],
        start_tm: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
        end_tm: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
        is_xcx: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
        web_id: [{
          required: true,
          message: '请输入CNZZ的WEB_ID',
          trigger: 'blur'
        }, {
          pattern: /^-?\d+\.?\d*$/,
          message: 'WEB_ID必须是数字',
          trigger: 'blur'
        }]
      },
      checkAll: false,
      checkedHours: [],
      hours: hourOptions,
      isIndeterminate: false
    };
  },
  created: function created() {
    this.getList();
    this.setDialogWidth();
  },
  mounted: function mounted() {
    var _this = this;

    window.onresize = function () {
      _this.setDialogWidth();
    };
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedHours = val ? hourOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedHoursChange: function handleCheckedHoursChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.hours.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.hours.length;
    },
    setDialogWidth: function setDialogWidth() {
      var val = document.body.clientWidth;
      var def = 800; // 默认宽度

      if (val < def) {
        this.dialogWidth = '100%';
      } else {
        this.dialogWidth = def + 'px';
      }
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _appletSetting.fetchList)(this.listQuery).then(function (res) {
        if (res.STATUS == 2000) {
          res.DATA.items.map(function (o, i) {
            res.DATA.items[i].link = JSON.parse(res.DATA.items[i].link);
            res.DATA.items[i].start_tm = (0, _utils.parseTime)(res.DATA.items[i].start_tm);
            res.DATA.items[i].end_tm = (0, _utils.parseTime)(res.DATA.items[i].end_tm);
          });
          _this2.list = res.DATA.items;
        }

        _this2.total = res.DATA.totalSize;
        _this2.listLoading = false;
      }).catch(function (err) {
        _this2.listLoading = false;
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        sort: '',
        block_linkid: '',
        manufacturer: [],
        start_tm: '',
        end_tm: '',
        is_xcx: 1,
        web_id: '',
        link: ''
      };
      this.checkAll = false;
      this.checkedHours = [];
      this.isIndeterminate = false;
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    createPlanShow: function createPlanShow() {
      var _this3 = this;

      this.resetTemp();
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    postCreatePlan: function postCreatePlan() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var arr = [{
            sort: _this4.temp.sort,
            name: _this4.temp.name,
            web_id: _this4.temp.web_id,
            link: _this4.temp.link
          }];
          var data = {
            manufacturer: _this4.temp.manufacturer.join(),
            start_tm: _this4.temp.start_tm,
            end_tm: _this4.temp.end_tm,
            block_linkid: _this4.temp.block_linkid,
            is_xcx: _this4.temp.is_xcx,
            link: JSON.stringify(arr),
            hour: _this4.checkedHours.join(',')
          };
          (0, _appletSetting.fetchAdd)(data).then(function (response) {
            if (response.STATUS == 2000) {
              _this4.handleFilter();

              _this4.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: "添加成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "添加失败，请重试",
                type: "error",
                duration: 2000
              });
            }
          });
        }
      });
    },
    updatePlanShow: function updatePlanShow(row) {
      var _this5 = this;

      this.temp2 = {
        id: row.id,
        manufacturer: row.manufacturer.split(","),
        block_linkid: row.block_linkid,
        start_tm: row.start_tm,
        end_tm: row.end_tm,
        is_xcx: row.is_xcx,
        link: JSON.stringify(row.link)
      };
      this.checkedHours = row.hour ? row.hour.split(',') : [];

      if (this.checkedHours.length == 0) {
        this.checkAll = false;
        this.isIndeterminate = false;
      } else if (this.checkedHours.length == hourOptions.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      } else {
        this.checkAll = false;
        this.isIndeterminate = true;
      }

      this.$nextTick(function () {
        _this5.$refs["dataForm2"].clearValidate();
      });
      this.dialogFormVisible2 = true;
    },
    postUpdatePlan: function postUpdatePlan() {
      var _this6 = this;

      this.$refs["dataForm2"].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this6.temp2));
          data.manufacturer = data.manufacturer.join();
          data.hour = _this6.checkedHours.join(',');
          (0, _appletSetting.fetchUpdate)(data).then(function (res) {
            _this6.dialogFormVisible2 = false;

            if (res.STATUS == 2000) {
              (0, _elementUi.Message)({
                message: "更新成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "更新失败，请重试",
                type: "error",
                duration: 2000
              });
            }

            _this6.getList();
          }).catch(function (err) {
            _this6.dialogFormVisible2 = false;
            (0, _elementUi.Message)({
              message: "更新失败，请重试",
              type: "error",
              duration: 2000
            });
          });
        }
      });
    },
    deletePlan: function deletePlan(row) {
      var _this7 = this;

      this.$confirm("确认删除该条记录吗？").then(function (_) {
        (0, _appletSetting.fetchDel)({
          id: row.id
        }).then(function (res) {
          (0, _elementUi.Message)({
            message: "删除成功",
            type: "success",
            duration: 2000
          });
          _this7.listQuery.page = 1;

          _this7.getList();
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: "删除失败，请重试",
            type: "error",
            duration: 2000
          });
        });
        done();
      }).catch(function (_) {});
    },
    createLinkShow: function createLinkShow(row) {
      var _this8 = this;

      this.dialogStatus = 'create';
      this.nowData = {
        id: row.id,
        manufacturer: row.manufacturer,
        start_tm: row.start_tm,
        end_tm: row.end_tm,
        is_xcx: row.is_xcx,
        link: row.link
      };
      this.temp3 = {
        name: '',
        sort: '',
        web_id: '',
        link: ''
      }, this.$nextTick(function () {
        _this8.$refs["dataForm3"].clearValidate();
      });
      this.dialogFormVisible3 = true;
    },
    postCreateLink: function postCreateLink() {
      var _this9 = this;

      this.$refs["dataForm3"].validate(function (valid) {
        if (valid) {
          // 插入数组
          _this9.nowData.link.push(_this9.temp3); // 按权重排序


          _this9.nowData.link = _this9.nowData.link.sort(function (a, b) {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return 1;
            } else if (parseInt(a.sort) == parseInt(b.sort)) {
              return 0;
            } else {
              return -1;
            }
          }); // 转JSON

          _this9.nowData.link = JSON.stringify(_this9.nowData.link);
          (0, _appletSetting.fetchUpdate)(_this9.nowData).then(function (response) {
            if (response.STATUS == 2000) {
              _this9.dialogFormVisible3 = false;

              _this9.getList();

              (0, _elementUi.Message)({
                message: "添加成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "添加失败，请重试",
                type: "error",
                duration: 2000
              });
            }
          });
        }
      });
    },
    updateLinkShow: function updateLinkShow(row, index) {
      this.dialogStatus = 'update';
      this.index = index;
      this.nowData = {
        id: row.id,
        manufacturer: row.manufacturer,
        start_tm: row.start_tm,
        end_tm: row.end_tm,
        is_xcx: row.is_xcx,
        link: row.link
      };
      this.temp3 = this.nowData.link[index];
      this.dialogFormVisible3 = true;
    },
    postUpdateLink: function postUpdateLink() {
      var _this10 = this;

      this.$refs["dataForm3"].validate(function (valid) {
        if (valid) {
          // 插入数组
          _this10.nowData.link[_this10.index] = _this10.temp3; // 按权重排序

          _this10.nowData.link = _this10.nowData.link.sort(function (a, b) {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return 1;
            } else if (parseInt(a.sort) == parseInt(b.sort)) {
              return 0;
            } else {
              return -1;
            }
          }); // 转JSON

          _this10.nowData.link = JSON.stringify(_this10.nowData.link);
          (0, _appletSetting.fetchUpdate)(_this10.nowData).then(function (response) {
            if (response.STATUS == 2000) {
              _this10.dialogFormVisible3 = false;

              _this10.getList();

              (0, _elementUi.Message)({
                message: "编辑成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "编辑失败，请重试",
                type: "error",
                duration: 2000
              });
            }
          });
        }
      });
    },
    deleteLink: function deleteLink(row, index) {
      var _this11 = this;

      this.$confirm("确认删除该条记录吗？").then(function (_) {
        _this11.nowData = {
          id: row.id,
          manufacturer: row.manufacturer,
          start_tm: row.start_tm,
          end_tm: row.end_tm,
          is_xcx: row.is_xcx,
          link: row.link
        }; // 插入数组

        _this11.nowData.link.splice(index, 1); // 转JSON


        _this11.nowData.link = JSON.stringify(_this11.nowData.link);
        (0, _appletSetting.fetchUpdate)(_this11.nowData).then(function (response) {
          if (response.STATUS == 2000) {
            _this11.dialogFormVisible3 = false;

            _this11.getList();

            (0, _elementUi.Message)({
              message: "删除成功",
              type: "success",
              duration: 2000
            });
          } else {
            (0, _elementUi.Message)({
              message: "删除失败，请重试",
              type: "error",
              duration: 2000
            });
          }
        });
      }).catch(function (_) {});
    }
  }
};
exports.default = _default;