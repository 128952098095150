"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _baiduCdn = require("@/api/shieldControl/baiduCdn");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BaiduCdn',
  data: function data() {
    return {
      temp: {
        url: ''
      }
    };
  },
  mounted: function mounted() {},
  filters: {},
  methods: {
    updateData: function updateData() {
      if (!this.temp.url) {
        (0, _elementUi.Message)({
          type: "error",
          message: "请输入正确的对象路径"
        });
        return;
      }

      (0, _baiduCdn.bashBaiduCdn)(this.temp).then(function (r) {
        if (r.STATUS == 2000) {
          (0, _elementUi.Message)({
            type: "success",
            message: "刷新成功"
          });
        } else {
          (0, _elementUi.Message)({
            type: "error",
            message: "刷新cdn失败"
          });
        }
      });
    }
  }
};
exports.default = _default;