"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/appadmin/index");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'activityControl',
  data: function data() {
    return {
      globalInfo: null,
      upload1: {
        del_name: ''
      },
      upload2: {
        del_name: ''
      },
      timer: null,
      limitOptions: [{
        value: 0,
        label: '输入指定链接ID'
      }, {
        value: 1,
        label: '输入屏蔽链接ID'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;

    this.getGlobalSetting();
    this.timer = setInterval(function () {
      (0, _index.fetchUpdate)().then(function (r) {
        if (!r.DATA) {
          _this.getGlobalSetting();
        }
      });
    }, 2000);
  },
  filters: {},
  methods: {
    getGlobalSetting: function getGlobalSetting() {
      var _this2 = this;

      (0, _index.getGlobalSetting)().then(function (r) {
        _this2.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null; // if(!this.globalInfo.activityControl.hasOwnProperty('activityLinksList')){
        //   this.$set(this.globalInfo.activityControl,'activityLinksList',[])
        //   this.globalInfo.activityControl.newWebUrlArr.map(item => {
        //     if(item){
        //       this.globalInfo.activityControl.activityLinksList.push({
        //         activityName:'通用活动',
        //         activityLinkUrl:item,
        //         activityType:0,
        //         activityLimitIds:''
        //       })
        //     }
        //   })
        // }
      });
    },
    // 添加活动页链接
    addWebUrl: function addWebUrl() {
      this.globalInfo.activityControl.activityLinksList.push({
        activityName: '',
        activityLinkUrl: "",
        activityType: 0,
        activityLimitIds: ''
      });
    },
    // 删除活动页链接
    delWebUrl: function delWebUrl(index) {
      this.globalInfo.activityControl.activityLinksList.splice(index, 1);
    },
    // 添加活动页链接
    newAddWebUrl: function newAddWebUrl() {
      this.globalInfo.activityControl.activityLinksList.push('');
    },
    // 删除活动页链接
    newDelWebUrl: function newDelWebUrl(index) {
      this.globalInfo.activityControl.activityLinksList.splice(index, 1);
    },
    updateConfig: function updateConfig() {
      var _this3 = this;

      var canSave = true;
      this.globalInfo.activityControl.activityLinksList.map(function (o) {
        if (!o.activityLinkUrl) {
          canSave = false;
        }
      });

      if (canSave) {
        (0, _index.setGlobalSetting)({
          jsonData: escape(JSON.stringify(this.globalInfo))
        }).then(function (r) {
          _this3.getGlobalSetting();

          (0, _elementUi.Message)({
            type: "success",
            message: "操作成功"
          });
        });
      } else {
        (0, _elementUi.Message)({
          type: "error",
          message: "活动地址必须填写，不填请删除"
        });
      }
    },
    uploadSuccess1: function uploadSuccess1(response) {
      if (response.code == 2000) {
        this.globalInfo.activityControl.activity.jumpImage1 = response.msg;
        this.updateConfig();
      } else {
        (0, _elementUi.Message)({
          message: '上传素材失败，请重试',
          type: 'error',
          duration: 2000
        });
      }
    },
    uploadSuccess2: function uploadSuccess2(response) {
      if (response.code == 2000) {
        this.globalInfo.activityControl.activity.jumpImage2 = response.msg;
        this.updateConfig();
      } else {
        (0, _elementUi.Message)({
          message: '上传素材失败，请重试',
          type: 'error',
          duration: 2000
        });
      }
    },
    uploadError: function uploadError() {
      (0, _elementUi.Message)({
        message: '上传素材失败，请重试',
        type: 'error',
        duration: 2000
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  }
};
exports.default = _default;