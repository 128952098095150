"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _debug = require("@/api/baseControl/debug");

var _index = require("@/api/appadmin/index");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

var _vuex = require("vuex");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: "debug",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      that: this,
      totalSize: 100,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        daili: "",
        link_id: "",
        convertid: ""
      },
      appList: [],
      platformArr: [],
      temp: {
        id: undefined,
        daili: '',
        qudao: '',
        link_id: '',
        convertid: 0,
        convert_rate: 1,
        type: 1,
        platform: 1,
        pkg: ''
      },
      dialogFormVisible: false,
      dialogStatus: "create",
      dialogWidth: 0,
      textMap: {
        update: "修改联调",
        create: "新建联调"
      },
      data: {},
      dailiList: [],
      rules: {
        link_id: [{
          required: true,
          message: '请输入链接ID',
          trigger: 'blur'
        }, {
          pattern: /^-?\d+\.?\d*$/,
          message: '链接ID数字值',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择联调方式',
          trigger: 'blur'
        }],
        pkg: [{
          required: true,
          message: '请选择联调应用',
          trigger: 'blur'
        }],
        platform: [{
          required: true,
          message: '请选择联调平台',
          trigger: 'blur'
        }],
        convert_rate: [{
          required: true,
          message: '请输入回传比例',
          trigger: 'blur'
        }, {
          pattern: /^(0|[1-9]\d{0,2}|1000)$/,
          message: '请输入数字，且范围在0-1000',
          trigger: 'blur'
        }],
        convertid: [{
          required: true,
          message: '请输入联调转化id',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["cur_app_name"])),
  created: function created() {
    this.getList();
    this.getApp();
    this.setDialogWidth();
    this.getDaili();
    this.ltGetPlatform();
  },
  filters: {},
  mounted: function mounted() {
    var _this = this;

    var vm = this;

    window.onresize = function () {
      return function () {
        _this.setDialogWidth();
      }();
    };
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getApp: function getApp() {
      var _this2 = this;

      (0, _debug.getAppList)().then(function (response) {
        if (response.STATUS == 2000) {
          _this2.appList = response.DATA;
        } else {
          (0, _elementUi.Message)({
            message: '获取包名列表失败，请刷新',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    platformFilter: function platformFilter(str) {
      var name;
      this.platformArr.forEach(function (i) {
        if (str == i.id) {
          name = i.name;
        }
      });
      return name;
    },
    handleSizeChange: function handleSizeChange(v) {
      this.listQuery.limit = v;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(v) {
      this.listQuery.page = v;
      this.getList();
    },
    getDaili: function getDaili() {
      var _this3 = this;

      (0, _debug.ltDaili)().then(function (res) {
        if (res.STATUS == 2000) {
          _this3.dailiList = res.DATA;
        } else {
          (0, _elementUi.Message)({
            message: "获取代理商列表失败，请重试",
            type: "error",
            duration: 2000
          });
          _this3.dailiList = [];
        }
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: "获取代理商列表失败，请重试",
          type: "error",
          duration: 2000
        });
        _this3.dailiList = [];
      });
    },
    ltGetPlatform: function ltGetPlatform() {
      var _this4 = this;

      (0, _debug.getPlatform)().then(function (res) {
        if (res.STATUS == 2000) {
          _this4.platformArr = res.DATA;
        } else {
          (0, _elementUi.Message)({
            message: "获取平台失败，请重试",
            type: "error",
            duration: 2000
          });
        }
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: "获取平台失败，请重试",
          type: "error",
          duration: 2000
        });
      });
    },
    deleteRow: function deleteRow(row) {
      var _this5 = this;

      this.$confirm("确认删除该联调方式？").then(function (_) {
        (0, _debug.ltDel)({
          id: row.id
        }).then(function (res) {
          (0, _elementUi.Message)({
            message: "删除信息成功",
            type: "success",
            duration: 2000
          });
          _this5.listQuery.page = 1;
          _this5.listQuery.daili = "";
          _this5.listQuery.link_id = "";
          _this5.listQuery.convertid = "";

          _this5.getList();
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: "删除信息失败，请重试",
            type: "error",
            duration: 2000
          });

          _this5.getList();
        });
        done();
      }).catch(function (_) {});
    },
    initSettingConfig: function initSettingConfig(type) {
      var vm = this;
      (0, _index.getGlobalSetting)().then(function (r) {
        vm.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null;
        vm.adConfigData = vm.globalInfo.adConfigSetting;
      });
    },
    selectHandleFilter: function selectHandleFilter() {
      this.getList();
    },
    handleCancel: function handleCancel() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.getList();
    },
    dateFormat: function dateFormat(v) {
      return _moment.default.unix(v / 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    setDialogWidth: function setDialogWidth() {
      var val = document.body.clientWidth;
      var def = 600; // 默认宽度

      if (val < def) {
        this.dialogWidth = "100%";
      } else {
        this.dialogWidth = def + "px";
      }
    },
    getList: function getList() {
      var _this6 = this;

      this.listLoading = true;
      (0, _debug.ltList)(this.listQuery).then(function (_) {
        if (_.STATUS == 2000) {
          _this6.list = _.DATA.items;
        }

        _this6.totalSize = _.DATA.totalSize;
        _this6.listLoading = false;
      }).catch(function (_) {
        _this6.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: null,
        daili: '',
        qudao: '',
        link_id: '',
        convertid: '',
        convert_rate: '',
        type: '',
        platform: 1,
        pkg: ''
      };
    },
    createShow: function createShow() {
      var _this7 = this;

      this.resetTemp();
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this7.$refs["dataForm"].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    postCreate: function postCreate() {
      var _this8 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          (0, _debug.ltAdd)(_this8.temp).then(function (response) {
            if (response.STATUS == 2000) {
              _this8.handleFilter();

              _this8.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: "添加联调信息成功",
                type: "success",
                duration: 2000
              });
            } else {
              _this8.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: "添加联调信息失败，请重试",
                type: "error",
                duration: 2000
              });
            }
          }).catch(function (err) {
            _this8.dialogFormVisible = false;
            (0, _elementUi.Message)({
              message: "添加联调信息失败，请重试",
              type: "error",
              duration: 2000
            });
          });
        }
      });
    },
    updateShow: function updateShow(row) {
      console.log(row);
      this.temp = JSON.parse(JSON.stringify(row));
      this.temp.type = row.type + '';
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
    },
    postUpdate: function postUpdate() {
      var _this9 = this;

      var data = JSON.parse(JSON.stringify(this.temp));
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          (0, _debug.ltUpdate)(data).then(function (res) {
            _this9.dialogFormVisible = false;

            if (res.STATUS == 2000) {
              (0, _elementUi.Message)({
                message: "更新联调信息成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "更新联调信息失败，请重试",
                type: "error",
                duration: 2000
              });
            }

            _this9.getList();
          }).catch(function (err) {
            _this9.dialogFormVisible = false;
            (0, _elementUi.Message)({
              message: "更新联调信息失败，请重试",
              type: "error",
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;