"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default = {
  name: 'redirect',
  data: function data() {
    return {
      tableKey: 0
    };
  },
  created: function created() {},
  methods: {}
};
exports.default = _default;