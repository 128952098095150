var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", [
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "margin_bottom",
              attrs: {
                type: "daterange",
                align: "left",
                "unlink-panels": "",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                "picker-options": _vm.pickerOptions2
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.value1,
                callback: function($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1"
              }
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                staticStyle: { width: "150px" },
                attrs: { filterable: "", placeholder: "请选择快应用" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.pkg_id,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "pkg_id", $$v)
                  },
                  expression: "listQuery.pkg_id"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "全部应用", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.pkg, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.app_name, value: item.id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                staticStyle: { width: "150px" },
                attrs: { filterable: "", placeholder: "请选择渠道号" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.qudao,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "qudao", $$v)
                  },
                  expression: "listQuery.qudao"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "全部渠道", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.qudao, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.qudao, value: item.qudao }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "margin_bottom",
              staticStyle: { width: "240px" },
              attrs: { placeholder: "请输入链接id,没有链接id为888" },
              model: {
                value: _vm.listQuery.link_id,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "link_id", $$v)
                },
                expression: "listQuery.link_id"
              }
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "margin_bottom",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入落地页" },
              model: {
                value: _vm.listQuery.referer,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "referer", $$v)
                },
                expression: "listQuery.referer"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilter }
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom btn",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.handleCancel }
              },
              [_vm._v("\n        重置\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom btn",
                attrs: { type: "primary", icon: _vm.clickDownloadIcon },
                on: { click: _vm.handleDownload }
              },
              [_vm._v("\n        导出表格\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "日期", "min-width": "155", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("timerFilter")(row.add_tm)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "落地页", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.referer))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道号", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.qudao))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "链接ID", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.link_id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "归属厂商", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.factory))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投诉机型", "min-width": "90", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.model))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投诉入口", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(_vm._f("typeFilter")(row.type)))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "IP", "min-width": "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ip))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上报次数", "min-width": "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.callback_num))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投诉理由", "min-width": "260", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.msg))])]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageShow",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageHidden",
        attrs: {
          layout: "prev, pager, next",
          small: true,
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }