"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _elementUi = require("element-ui");

var _index = require("@/api/appadmin/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      disabled: false,
      formLabelWidth: "140px",
      adConfigData: null,
      globalInfo: {},
      activeName: '1',
      webModal: false,
      dialogFormVisible: false,
      addLinkVisible: false,
      curWebData: {
        id: '',
        title: '',
        materialUrl: '',
        changshang: [],
        targetUrl: '',
        switch: true
      },
      curApp: null,
      //当前操作的App
      type: "",
      formInfo: {
        id: "",
        name: "",
        // icon: "",
        packageName: "",
        okSkipSwitch: true,
        materialUrl: "",
        weight: 1,
        onlineTime: "",
        offlineTime: "",
        pullLinkList: [],
        switch: true // isDefault: false

      },
      linkForm: {
        id: "",
        title: "",
        url: "",
        materialUrl: ""
      },
      upload1: {
        del_name: ''
      },
      fileUrl1: '',
      file1: '',
      upload2: {
        del_name: ''
      },
      fileUrl2: '',
      file2: ''
    };
  },
  computed: {
    cur_app_name: {
      get: function get() {
        return this.$store.state.app.cur_app_name;
      },
      set: function set() {}
    },
    userName: {
      get: function get() {
        return this.$store.state.user.name;
      }
    }
  },
  watch: {
    cur_app_name: function cur_app_name(val, oldVal) {
      this.initSettingConfig('init');
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted: function mounted() {
    var _this = this;

    var vm = this;
    vm.initSettingConfig('init');

    if (vm.userName == 'operate') {
      vm.disabled = true;
    }

    this.timer = setInterval(function () {
      (0, _index.fetchUpdate)().then(function (r) {
        if (!r.DATA) {
          _this.initSettingConfig('init');
        }
      });
    }, 2000);
  },
  methods: {
    initSettingConfig: function initSettingConfig(type) {
      var vm = this;
      (0, _index.getGlobalSetting)().then(function (r) {
        vm.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null;
        vm.adConfigData = vm.globalInfo.adConfigSetting; // console.log(JSON.stringify(vm.globalInfo))
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview1: function handlePreview1(file, fileList) {
      this.fileUrl1 = URL.createObjectURL(file.raw);
      this.file1 = file.raw;
      this.$refs.upload1.submit();
    },
    uploadSuccess1: function uploadSuccess1(response) {
      if (response.code == 2000) {
        this.curWebData.materialUrl = response.msg;
      } else {
        (0, _elementUi.Message)({
          message: '上传素材失败，请重试',
          type: 'error',
          duration: 2000
        });
      }
    },
    uploadError1: function uploadError1() {
      this.nowloding.close();
      (0, _elementUi.Message)({
        message: '上传素材失败，请重试',
        type: 'error',
        duration: 2000
      });
    },
    handlePreview2: function handlePreview2(file, fileList) {
      this.fileUrl2 = URL.createObjectURL(file.raw);
      this.file2 = file.raw;
      this.$refs.upload2.submit();
    },
    uploadSuccess2: function uploadSuccess2(response) {
      if (response.code == 2000) {
        this.linkForm.materialUrl = response.msg;
      } else {
        (0, _elementUi.Message)({
          message: '上传素材失败，请重试',
          type: 'error',
          duration: 2000
        });
      }
    },
    uploadError2: function uploadError2() {
      this.nowloding.close();
      (0, _elementUi.Message)({
        message: '上传素材失败，请重试',
        type: 'error',
        duration: 2000
      });
    },
    handleClick: function handleClick(tab, event) {// console.log(tab, event)
    },
    showWebModal: function showWebModal(item) {
      var vm = this;
      console.log(item);

      if (item) {
        if (!item.changshang) {
          item.changshang = [];
        }

        vm.curWebData = (0, _objectSpread2.default)({}, item);
        this.fileUrl1 = item.materialUrl;
        vm.webModal = true;
      } else {
        vm.webModal = true;
      }
    },
    hideWebModal: function hideWebModal() {
      var vm = this;
      this.fileUrl1 = '';
      this.file1 = '';
      vm.curWebData = {
        id: '',
        title: '',
        changshang: [],
        materialUrl: '',
        targetUrl: '',
        switch: true
      };
      vm.webModal = false;
    },
    // 修改广告权重
    onChangeAdvWeight: function onChangeAdvWeight() {
      this.adConfigData.advWeight = this.adConfigData.advWeight.sort(function (a, b) {
        if (parseInt(a.weight) < parseInt(b.weight)) {
          return 1;
        } else if (parseInt(a.weight) == parseInt(b.weight)) {
          return 0;
        } else {
          return -1;
        }
      });
    },
    // 区分四大厂商权重
    onChangeAdvWeightNew: function onChangeAdvWeightNew(type) {
      this.adConfigData.advWeightNew[type] = this.adConfigData.advWeightNew[type].sort(function (a, b) {
        if (parseInt(a.weight) < parseInt(b.weight)) {
          return 1;
        } else if (parseInt(a.weight) == parseInt(b.weight)) {
          return 0;
        } else {
          return -1;
        }
      });
    },
    submitWebAdd: function submitWebAdd() {
      this.nowloding = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var vm = this;
      console.log('vm.curWebData', vm.curWebData);

      if (vm.curWebData.id) {
        // 编辑
        this.adConfigData.insertScreenAdvList = this.adConfigData.insertScreenAdvList.map(function (item) {
          if (item.id == vm.curWebData.id) {
            item = (0, _objectSpread2.default)({}, vm.curWebData);
          }

          return item;
        });
        vm.hideWebModal();
        vm.handleSubmitAdConfigSetting();
        this.nowloding.close();
      } else {
        vm.curWebData.id = new Date().getTime();
        vm.adConfigData.insertScreenAdvList.push(vm.curWebData);
        vm.hideWebModal();
        vm.handleSubmitAdConfigSetting();
        this.nowloding.close();
      }
    },
    handleSubmitAdConfigSetting: function handleSubmitAdConfigSetting() {
      var vm = this; // 点击率配置过滤未填写完全的配置

      vm.filterNullSetting(); // 广告id配置过滤未填写完全的配置

      vm.filterLadderNullSetting(); // 更新全局配置

      vm.globalInfo.adConfigSetting = vm.adConfigData;
      (0, _index.setGlobalSetting)({
        // config: encodeURI(JSON.stringify(vm.adConfigData)),
        jsonData: escape(JSON.stringify(vm.globalInfo))
      }).then(function (res) {
        vm.initSettingConfig();
        vm.webModal = false;
        vm.dialogFormVisible = false;
        vm.addLinkVisible = false;
        (0, _elementUi.Message)({
          type: "success",
          message: "操作成功"
        });
      });
    },
    onConfirm: function onConfirm() {
      var _this2 = this;

      if (this.type == "add") {
        this.adConfigData.pullAppList.push(this.formInfo); //添加数据

        this.setGlobalSetting();
      } else if (this.type == "edit") {
        this.adConfigData.pullAppList.map(function (o, i) {
          if (o.id == _this2.formInfo.id) {
            o.name = _this2.formInfo.name; // o.icon = this.formInfo.icon;

            o.packageName = _this2.formInfo.packageName;
            o.okSkipSwitch = _this2.formInfo.okSkipSwitch;
            o.materialUrl = _this2.formInfo.materialUrl;
            o.weight = _this2.formInfo.weight;
            o.onlineTime = _this2.formInfo.onlineTime;
            o.offlineTime = _this2.formInfo.offlineTime;
            o.switch = _this2.formInfo.switch; // o.isDefault = this.formInfo.isDefault;
          }
        });
        this.setGlobalSetting();
      }
    },
    onDelWeb: function onDelWeb(row) {
      var _this3 = this;

      var self = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // console.log(row);
        _this3.adConfigData.insertScreenAdvList.map(function (o, i) {
          if (o.id == row.id) {
            _this3.adConfigData.insertScreenAdvList.splice(i, 1);
          }
        });

        _this3.setGlobalSetting();
      }).catch(function (r) {
        _this3.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    onDel: function onDel(row) {
      var _this4 = this;

      var self = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.adConfigData.pullAppList.map(function (o, i) {
          if (o.id == row.id) {
            _this4.adConfigData.pullAppList.splice(i, 1);
          }
        });

        _this4.setGlobalSetting();
      }).catch(function (r) {
        _this4.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    //编辑
    onEdit: function onEdit(item) {
      this.type = "edit";
      this.formInfo = {
        id: item.id,
        name: item.name,
        // icon: item.icon,
        packageName: item.packageName,
        okSkipSwitch: item.okSkipSwitch,
        materialUrl: item.materialUrl,
        weight: item.weight,
        onlineTime: item.onlineTime,
        offlineTime: item.offlineTime,
        switch: item.switch // isDefault: item.isDefault

      };
      this.dialogFormVisible = true;
    },
    //新增广告
    addSearch: function addSearch() {
      this.type = "add";
      this.formInfo = {
        id: new Date().getTime(),
        name: "",
        // icon: "",
        packageName: "",
        okSkipSwitch: true,
        materialUrl: "",
        weight: 1,
        onlineTime: "",
        offlineTime: "",
        pullLinkList: [],
        switch: true // isDefault: false

      };
      this.dialogFormVisible = true;
    },
    //添加链接
    onAddLink: function onAddLink(item) {
      this.curApp = item;
      this.linkType = "add";
      this.linkForm = {
        id: new Date().getTime(),
        title: "",
        url: "",
        materialUrl: ""
      };
      this.fileUrl2 = '';
      this.addLinkVisible = true;
    },
    //编辑指定链接
    onEditLink: function onEditLink(row, id) {
      var _this5 = this;

      this.curApp = row;
      this.linkType = "edit";
      this.adConfigData.pullAppList.map(function (o, i) {
        if (o.id == row.id) {
          o.pullLinkList.map(function (oo, ii) {
            if (oo.id == id) {
              _this5.linkForm = {
                id: oo.id,
                url: oo.url,
                title: oo.title,
                materialUrl: oo.materialUrl
              };
              _this5.fileUrl2 = oo.materialUrl;
              console.log(_this5.linkForm);
            }
          });
        }
      });
      this.addLinkVisible = true;
    },
    //删除指定链接
    onDelLink: function onDelLink(row, id) {
      var _this6 = this;

      var self = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this6.adConfigData.pullAppList.map(function (o, i) {
          if (o.id == row.id) {
            o.pullLinkList.map(function (oo, ii) {
              if (oo.id == id) {
                o.pullLinkList.splice(ii, 1);
              }
            });
          }
        });

        _this6.setGlobalSetting();
      }).catch(function (r) {
        _this6.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    setGlobalSetting: function setGlobalSetting() {
      this.adConfigData.pullAppList.sort(function (a, b) {
        if (parseInt(a.weight) < parseInt(b.weight)) {
          return 1;
        } else if (parseInt(a.weight) == parseInt(b.weight)) {
          return 0;
        } else {
          return -1;
        }
      });
      this.handleSubmitAdConfigSetting(this.cur_app_name);
    },
    //添加链接点击确定
    onAddLinkConfirm: function onAddLinkConfirm() {
      var _this7 = this;

      if (this.linkType == "add") {
        this.adConfigData.pullAppList.map(function (o, i) {
          if (o.id == _this7.curApp.id) {
            o.pullLinkList.push(_this7.linkForm);
          }
        });
        this.setGlobalSetting();
      } else if (this.linkType == "edit") {
        this.adConfigData.pullAppList.map(function (o, i) {
          if (o.id == _this7.curApp.id) {
            o.pullLinkList.map(function (oo, ii) {
              if (oo.id == _this7.linkForm.id) {
                oo.url = _this7.linkForm.url;
                oo.title = _this7.linkForm.title;
                oo.materialUrl = _this7.linkForm.materialUrl;
              }
            });
          }
        });
        this.setGlobalSetting();
      }
    },

    /**
     * 厂商广告id点击率配置相关方法
     */
    // 添加广告id
    addNativeId: function addNativeId(type) {
      this.adConfigData.changshang[type].randomNativeId.push({
        id: "",
        minRate: "",
        maxRate: ""
      });
    },
    // 删除活动页链接
    delNativeId: function delNativeId(type, index) {
      this.adConfigData.changshang[type].randomNativeId.splice(index, 1);
    },
    // 点击率配置过滤未填写完全的配置
    filterNullSetting: function filterNullSetting() {
      var _this8 = this;

      var arr = ['oppo', 'vivo', 'xiaomi', 'huawei'];
      arr.map(function (o) {
        _this8.adConfigData.changshang[o].randomNativeId = _this8.adConfigData.changshang[o].randomNativeId.filter(function (oo) {
          return oo.id && oo.minRate && oo.maxRate;
        });
      });
    },

    /**
     * 厂商广告id配置相关方法
     */
    // 添加广告id
    addLadderNativeId: function addLadderNativeId(type) {
      this.adConfigData.changshangnew[type].randomNativeId.push({
        id: "",
        num: ""
      });
    },
    // 删除活动页链接
    delLadderNativeId: function delLadderNativeId(type, index) {
      this.adConfigData.changshangnew[type].randomNativeId.splice(index, 1);
    },
    // 广告id配置过滤未填写完全的配置
    filterLadderNullSetting: function filterLadderNullSetting() {
      var _this9 = this;

      var arr = ['oppo', 'vivo', 'xiaomi', 'huawei'];
      arr.map(function (o) {
        _this9.adConfigData.changshangnew[o].randomNativeId = _this9.adConfigData.changshangnew[o].randomNativeId.filter(function (oo) {
          return oo.id && oo.num;
        });
        _this9.adConfigData.changshangnew[o].randomNativeId = _this9.adConfigData.changshangnew[o].randomNativeId.sort(function (a, b) {
          return b.num - a.num;
        });
      });
    }
  }
};
exports.default = _default;