"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _report = require("@/api/shieldControl/report");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: 'report',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      value1: '',
      listQuery: {
        page: 1,
        limit: 20,
        pkg: '',
        manufacturer: '',
        start_tm: '',
        end_tm: ''
      },
      device_pv: '',
      device_uv: '',
      shield_pv: '',
      shield_uv: '',
      pickerOptions2: {
        shortcuts: [{
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, start]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      if (this.value1) {
        this.listQuery.start_tm = this.value1[0];
        this.listQuery.end_tm = this.value1[1];
      } else {
        this.listQuery.start_tm = '';
        this.listQuery.end_tm = '';
      }

      (0, _report.fetchReport)(this.listQuery).then(function (response) {
        _this.listLoading = false;

        if (response.STATUS == 2000) {
          _this.list = response.DATA.items;
          _this.total = response.DATA.totalSize;
          _this.device_pv = response.COUNTARR[0].device_pv;
          _this.device_uv = response.COUNTARR[0].device_uv;
          _this.shield_pv = response.COUNTARR[0].device_w_pv;
          _this.shield_uv = response.COUNTARR[0].device_w_uv;
        } else {
          (0, _elementUi.Message)({
            message: response.DEC || '获取设备报表失败',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCancel: function handleCancel() {
      this.listQuery.page = 1;
      this.listQuery.pkg = '';
      this.listQuery.manufacturer = '';
      this.value1 = '';
      this.getList();
    },
    getSummaries: function getSummaries() {
      var sums = ['总计', '-', this.device_pv, this.device_uv, this.shield_pv, this.shield_uv];
      return sums;
    }
  }
};
exports.default = _default;