"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/core-js/modules/es6.array.iterator.js");

require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/core-js/modules/es6.promise.js");

require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/core-js/modules/es6.object.assign.js");

require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _vCharts = _interopRequireDefault(require("v-charts"));

require("@/icons");

require("@/permission");

// A modern alternative to CSS resets
// lang i18n 
// import histroy from 'connect-history-api-fallback'
// global css 
// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }
// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.use(_vCharts.default); // Vue.use(histroy())


_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});