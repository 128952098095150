var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.globalInfo
        ? _c(
            "div",
            { staticClass: "search-box" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.updateConfig()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px 20px 0 20px",
                "margin-bottom": "20px"
              }
            },
            _vm._l(_vm.globalInfo.activityControl.activityLinksList, function(
              item,
              index
            ) {
              return _c(
                "div",
                { key: index, staticStyle: { height: "60px" } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c("div", { staticClass: "my_label" }, [
                        _vm._v("H5名称" + _vm._s(index + 1) + "：")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入网址" },
                            model: {
                              value: item.activityName,
                              callback: function($$v) {
                                _vm.$set(item, "activityName", $$v)
                              },
                              expression: "item.activityName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c("div", { staticClass: "my_label" }, [
                        _vm._v("H5活动地址" + _vm._s(index + 1) + "：")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入网址" },
                            model: {
                              value: item.activityLinkUrl,
                              callback: function($$v) {
                                _vm.$set(item, "activityLinkUrl", $$v)
                              },
                              expression: "item.activityLinkUrl"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c(
                      "div",
                      { staticClass: "row_style" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: item.activityType,
                              callback: function($$v) {
                                _vm.$set(item, "activityType", $$v)
                              },
                              expression: "item.activityType"
                            }
                          },
                          _vm._l(_vm.limitOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "my_label" }, [_vm._v(" ：")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { flex: "1", "padding-right": "20px" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "多个链接ID请用英文逗号隔开"
                              },
                              model: {
                                value: item.activityLimitIds,
                                callback: function($$v) {
                                  _vm.$set(item, "activityLimitIds", $$v)
                                },
                                expression: "item.activityLimitIds"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _vm.globalInfo.activityControl.activityLinksList.length >
                      1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.delWebUrl(index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      index ==
                      _vm.globalInfo.activityControl.activityLinksList.length -
                        1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.addWebUrl()
                                }
                              }
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _vm._v("\n      每个用户每天可抽奖次数：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value:
                        _vm.globalInfo.activityControl.activity.newAllChance,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.globalInfo.activityControl.activity,
                          "newAllChance",
                          $$v
                        )
                      },
                      expression:
                        "globalInfo.activityControl.activity.newAllChance"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _vm._v("\n      活动页广告图片展示间隔：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写广告展示时间，单位毫秒" },
                    model: {
                      value:
                        _vm.globalInfo.activityControl.activity
                          .activePopAdvShowTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.globalInfo.activityControl.activity,
                          "activePopAdvShowTime",
                          $$v
                        )
                      },
                      expression:
                        "globalInfo.activityControl.activity.activePopAdvShowTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "60px" } },
                [
                  _vm._v("\n      活动页弹窗自动关闭间隔：\n      "),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请填写弹窗关闭时间，单位秒" },
                    model: {
                      value:
                        _vm.globalInfo.activityControl.activity
                          .activePopCloseTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.globalInfo.activityControl.activity,
                          "activePopCloseTime",
                          $$v
                        )
                      },
                      expression:
                        "globalInfo.activityControl.activity.activePopCloseTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "my_label",
                        staticStyle: { width: "260px" }
                      },
                      [_vm._v("无抽奖机会时是否触发后退弹窗:")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "my_label" },
                      [
                        _c("el-switch", {
                          model: {
                            value:
                              _vm.globalInfo.activityControl.activity
                                .leaveSwitch,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.globalInfo.activityControl.activity,
                                "leaveSwitch",
                                $$v
                              )
                            },
                            expression:
                              "globalInfo.activityControl.activity.leaveSwitch"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }