var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "140px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.updateConfig()
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-left": "37px", width: "1000px" },
              attrs: {
                model: _vm.globalInfo,
                "label-width": "200px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户协议地址:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入网址" },
                    model: {
                      value: _vm.globalInfo.agreement.licensingUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.globalInfo.agreement, "licensingUrl", $$v)
                      },
                      expression: "globalInfo.agreement.licensingUrl"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "隐私政策地址:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入网址" },
                    model: {
                      value: _vm.globalInfo.agreement.privacy,
                      callback: function($$v) {
                        _vm.$set(_vm.globalInfo.agreement, "privacy", $$v)
                      },
                      expression: "globalInfo.agreement.privacy"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }