var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                staticStyle: { width: "150px" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.pkg,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "pkg", $$v)
                  },
                  expression: "listQuery.pkg"
                }
              },
              [
                _c("el-option", {
                  key: "-1",
                  attrs: { label: "全部应用", value: "" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "1",
                  attrs: { label: "时光柚", value: "cloud.quick.calendar" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "2",
                  attrs: { label: "星座吧", value: "cloud.quick.constellatory" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "3",
                  attrs: { label: "云工具", value: "cloud.quick.weather" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                staticStyle: { width: "150px" },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.manufacturer,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "manufacturer", $$v)
                  },
                  expression: "listQuery.manufacturer"
                }
              },
              [
                _c("el-option", {
                  key: "-1",
                  attrs: { label: "全部厂商", value: "" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "1",
                  attrs: { label: "小米", value: "xiaomi" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "2",
                  attrs: { label: "华为", value: "huawei" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "3",
                  attrs: { label: "OPPO", value: "oppo" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "4",
                  attrs: { label: "vivo", value: "vivo" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "5",
                  attrs: { label: "其它", value: "other" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "margin_bottom",
              attrs: {
                type: "daterange",
                align: "left",
                "unlink-panels": "",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                "picker-options": _vm.pickerOptions2
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.value1,
                callback: function($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilter }
              },
              [_vm._v("\n        搜索\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom btn",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.handleCancel }
              },
              [_vm._v("\n        重置\n      ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "时间", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.day))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "厂商", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.manufacturer))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备访问量", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.device_pv))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备数", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.device_uv))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "屏蔽访问量", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.device_w_pv))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "屏蔽设备数", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.device_w_uv))])]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageShow",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageHidden",
        attrs: {
          layout: "prev, pager, next",
          small: true,
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }