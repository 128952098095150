"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _verifyFile = require("@/api/baseControl/verifyFile");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: "verifyFile",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      temp: {
        name: '',
        val: ''
      },
      dialogFormVisible: false,
      dialogStatus: "create",
      dialogWidth: 0,
      textMap: {
        update: "小程序校验文件",
        create: "小程序校验文件"
      },
      rules: {
        name: [{
          required: true,
          message: '请填写文件名称',
          trigger: 'blur'
        }],
        val: [{
          required: true,
          message: '请填写内容',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
    this.setDialogWidth();
  },
  mounted: function mounted() {
    var _this = this;

    window.onresize = function () {
      _this.setDialogWidth();
    };
  },
  methods: {
    setDialogWidth: function setDialogWidth() {
      var val = document.body.clientWidth;
      var def = 600; // 默认宽度

      if (val < def) {
        this.dialogWidth = '100%';
      } else {
        this.dialogWidth = def + 'px';
      }
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _verifyFile.fetchList)(this.listQuery).then(function (res) {
        if (res.STATUS == 2000) {
          _this2.list = res.DATA.items;
        }

        _this2.total = res.DATA.totalSize;
        _this2.listLoading = false;
      }).catch(function (err) {
        _this2.listLoading = false;
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        name: '',
        val: ''
      };
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    createShow: function createShow() {
      var _this3 = this;

      this.resetTemp();
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    postCreate: function postCreate() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          (0, _verifyFile.fetchAdd)(_this4.temp).then(function (response) {
            if (response.STATUS == 2000) {
              _this4.handleFilter();

              _this4.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: "添加成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "添加失败，请重试",
                type: "error",
                duration: 2000
              });
            }
          }).catch(function (err) {
            (0, _elementUi.Message)({
              message: "添加失败，请重试",
              type: "error",
              duration: 2000
            });
          });
        }
      });
    },
    deleteRow: function deleteRow(row) {
      var _this5 = this;

      this.$confirm("确认删除该条记录吗？").then(function (_) {
        (0, _verifyFile.fetchDel)({
          id: row.id
        }).then(function (res) {
          (0, _elementUi.Message)({
            message: "删除成功",
            type: "success",
            duration: 2000
          });
          _this5.listQuery.page = 1;

          _this5.getList();
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: "删除失败，请重试",
            type: "error",
            duration: 2000
          });
        });
        done();
      }).catch(function (_) {});
    },
    updateShow: function updateShow(row) {
      console.log(row);
      this.temp = JSON.parse(JSON.stringify(row));
      this.temp.type = row.type + '';
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
    },
    postUpdate: function postUpdate() {
      var _this6 = this;

      var data = JSON.parse(JSON.stringify(this.temp));
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          ltUpdate(data).then(function (res) {
            _this6.dialogFormVisible = false;

            if (res.STATUS == 2000) {
              (0, _elementUi.Message)({
                message: "更新联调信息成功",
                type: "success",
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: "更新联调信息失败，请重试",
                type: "error",
                duration: 2000
              });
            }

            _this6.getList();
          }).catch(function (err) {
            _this6.dialogFormVisible = false;
            (0, _elementUi.Message)({
              message: "更新联调信息失败，请重试",
              type: "error",
              duration: 2000
            });
          });
        }
      });
    }
  }
};
exports.default = _default;