"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _shield = require("@/api/shieldControl/shield");

var _elementUi = require("element-ui");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'shield',
  data: function data() {
    return {
      temp: {
        blackarea: '',
        removearea: '',
        noadvarea: '',
        pkg: ''
      },
      temp1: {
        type: 2,
        android: '',
        pkg: ''
      },
      userTemp: {
        android: '',
        pkg: ''
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["cur_app_name"])), {}, {
    package: function _package() {
      if (this.cur_app_name == 'cloud.quick.calendar' || this.cur_app_name == 'cloud.quick.calendar.chuan' || this.cur_app_name == 'cloud.quick.calendar.three') {
        return 'cloud.quick.calendar';
      } else if (this.cur_app_name == 'cloud.quick.weather' || this.cur_app_name == 'cloud.quick.weather.chuan' || this.cur_app_name == 'cloud.quick.weather.three') {
        return 'cloud.quick.weather';
      } else if (this.cur_app_name == 'cloud.quick.constellatory' || this.cur_app_name == 'cloud.quick.constellatory.chuan' || this.cur_app_name == 'cloud.quick.constellatory.three') {
        return 'cloud.quick.constellatory';
      } else {
        return this.cur_app_name;
      }
    }
  }),
  mounted: function mounted() {
    this.getData();
    this.getUser();
  },
  filters: {},
  methods: {
    getData: function getData() {
      var _this = this;

      (0, _shield.getShield)({
        pkg: this.package
      }).then(function (r) {
        if (r.STATUS == 2000) {
          _this.temp.blackarea = r.DATA.blackarea;
          _this.temp.removearea = r.DATA.removearea;
          _this.temp.noadvarea = r.DATA.noadvarea;
        } else {
          (0, _elementUi.Message)({
            type: "error",
            message: "获取地区数据失败，请联系技术人员"
          });
        }
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      this.temp.pkg = this.package;
      (0, _shield.setShield)(this.temp).then(function (r) {
        if (r.STATUS == 2000) {
          _this2.setUser();
        } else {
          (0, _elementUi.Message)({
            type: "error",
            message: "修改黑名单地区失败"
          });
        }
      });
    },
    getUser: function getUser() {
      var _this3 = this;

      (0, _shield.fetchUser)({
        pkg: this.package
      }).then(function (r) {
        if (r.STATUS == 2000) {
          _this3.userTemp.android = r.DATA;
        } else {
          (0, _elementUi.Message)({
            type: "error",
            message: "获取用户白名单失败，请联系技术人员"
          });
        }
      });
    },
    setUser: function setUser() {
      this.userTemp.pkg = this.package;
      (0, _shield.updateUser)(this.userTemp).then(function (r) {
        if (r.STATUS == 2000) {
          (0, _elementUi.Message)({
            type: "success",
            message: "修改成功"
          });
        } else {
          (0, _elementUi.Message)({
            type: "error",
            message: "修改用户白名单失败"
          });
        }
      });
    },
    handleOneCache: function handleOneCache() {
      var _this4 = this;

      if (!this.temp1.android) {
        (0, _elementUi.Message)({
          message: '单个清除缓存需填写安卓id',
          type: 'error',
          duration: 2000
        });
        return;
      }

      this.temp1.pkg = this.package;
      this.temp1.type = 2;
      (0, _shield.fetchOneCache)(this.temp1).then(function (response) {
        if (response.STATUS == 2000) {
          (0, _elementUi.Message)({
            message: '清除单个缓存成功',
            type: 'success',
            duration: 2000
          });
          _this4.temp1.android = '';
        } else {
          (0, _elementUi.Message)({
            message: response.DEC || '清除单个缓存失败',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleAllCache: function handleAllCache() {
      var _this5 = this;

      _elementUi.MessageBox.confirm('确定要清除所有缓存吗', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.temp1.pkg = _this5.package;
        _this5.temp1.type = 1;
        (0, _shield.fetchAllCache)(_this5.temp1).then(function (response) {
          if (response.STATUS == 2000) {
            (0, _elementUi.Message)({
              message: '清除所有缓存成功',
              type: 'success',
              duration: 2000
            });
          } else {
            (0, _elementUi.Message)({
              message: response.DEC || '清除所有缓存失败',
              type: 'error',
              duration: 2000
            });
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;