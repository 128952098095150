var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择渠道"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.qudao,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "qudao", $$v)
                  },
                  expression: "listQuery.qudao"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有渠道", value: "" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "-1",
                  attrs: { label: "空渠道", value: "888" }
                }),
                _vm._v(" "),
                _vm._l(_vm.qudao, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.qudao, value: item.qudao }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  filterable: "",
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择链接id"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.link_id,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "link_id", $$v)
                  },
                  expression: "listQuery.link_id"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有链接", value: "" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "-1",
                  attrs: { label: "空链接", value: "x888" }
                }),
                _vm._v(" "),
                _vm._l(_vm.link, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.id, value: item.id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择手机品牌"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.manufacturer,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "manufacturer", $$v)
                  },
                  expression: "listQuery.manufacturer"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有手机品牌", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.manufacturer, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.event_id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择广告类型"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.ad_type,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "ad_type", $$v)
                  },
                  expression: "listQuery.ad_type"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有广告类型", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.ad_type, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.event_id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择触发场景"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.scenario,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "scenario", $$v)
                  },
                  expression: "listQuery.scenario"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有触发场景", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.scenario, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.event_id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择广告事件"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.ad_event,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "ad_event", $$v)
                  },
                  expression: "listQuery.ad_event"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有广告事件", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.ad_event, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.event_id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  filterable: "",
                  placeholder: "请选择广告名称"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.ad_name,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "ad_name", $$v)
                  },
                  expression: "listQuery.ad_name"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "所有广告名称", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.ad_name, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.event_id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "margin_bottom",
                attrs: {
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "请选择快应用"
                },
                on: { change: _vm.handleFilter },
                model: {
                  value: _vm.listQuery.pkg_id,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "pkg_id", $$v)
                  },
                  expression: "listQuery.pkg_id"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "全部应用", value: "" }
                }),
                _vm._v(" "),
                _vm._l(_vm.pkg, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.app_name, value: item.id }
                  })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "margin_bottom",
              attrs: {
                type: "daterange",
                align: "left",
                "unlink-panels": "",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                "picker-options": _vm.pickerOptions2
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.value1,
                callback: function($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom",
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.handleFilter }
              },
              [_vm._v("\n          搜索\n        ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom btn",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.handleCancel }
              },
              [_vm._v("\n          重置\n        ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "margin_bottom btn",
                staticStyle: { float: "right" },
                attrs: { type: "primary", icon: _vm.clickDownloadIcon },
                on: { click: _vm.handleDowload }
              },
              [_vm._v("\n          导出报表\n        ")]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "时间", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.day))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "链接id", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.link_id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道号", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.qudao))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "链接名称/渠道号",
              "min-width": "130",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.link_name + " / " + row.qudao))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "应用名称", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.app_name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机品牌", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.manufacturer))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "广告类型", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ad_type))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "触发场景", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.scenario))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "广告事件", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ad_event))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "广告名称", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ad_name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "触发人数", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.devices_num))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "触发次数", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.num))])]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageShow",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticClass: "pageHidden",
        attrs: {
          layout: "prev, pager, next",
          small: true,
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }