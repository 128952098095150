"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.link");

var _eventsDetail = require("@/api/advEvent/eventsDetail");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: 'EventsDetail',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      value1: [(0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}'), (0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}')],
      listQuery: {
        page: 1,
        limit: 20,
        manufacturer: [''],
        ad_type: [''],
        scenario: [''],
        ad_event: ['click'],
        ad_name: [''],
        qudao: [''],
        link_id: [''],
        pkg_id: [1],
        start_tm: '',
        end_tm: ''
      },
      uv: '',
      pv: '',
      ad_event: [],
      ad_name: [],
      ad_type: [],
      link: [],
      manufacturer: [],
      pkg: [],
      qudao: [],
      scenario: [],
      clickDownload: true,
      clickDownloadIcon: 'el-icon-download',
      pickerOptions2: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime());
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, start]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      if (this.value1) {
        this.listQuery.start_tm = this.value1[0];
        this.listQuery.end_tm = this.value1[1];
      } else {
        this.listQuery.start_tm = '';
        this.listQuery.end_tm = '';
      }

      var obj = {
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        manufacturer: JSON.stringify(this.listQuery.manufacturer),
        ad_type: JSON.stringify(this.listQuery.ad_type),
        scenario: JSON.stringify(this.listQuery.scenario),
        ad_event: JSON.stringify(this.listQuery.ad_event),
        ad_name: JSON.stringify(this.listQuery.ad_name),
        qudao: JSON.stringify(this.listQuery.qudao),
        link_id: JSON.stringify(this.listQuery.link_id),
        pkg_id: JSON.stringify(this.listQuery.pkg_id),
        start_tm: this.listQuery.start_tm,
        end_tm: this.listQuery.end_tm
      };
      (0, _eventsDetail.fetchList)(obj).then(function (response) {
        _this.listLoading = false;

        if (response.STATUS == 2000) {
          _this.list = response.DATA.items;
          _this.total = response.DATA.totalSize;
          _this.ad_event = response.SX.ad_event;
          _this.ad_name = response.SX.ad_name;
          _this.ad_type = response.SX.ad_type;
          _this.link = response.SX.link;
          _this.manufacturer = response.SX.manufacturer;
          _this.pkg = response.SX.pkg;
          _this.qudao = response.SX.qudao;
          _this.scenario = response.SX.scenario;
          _this.count_uv = response.COUNT.devices_num;
          _this.count_pv = response.COUNT.num;
        } else {
          (0, _elementUi.Message)({
            message: response.DEC || '获取事件报表失败',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCancel: function handleCancel() {
      this.listQuery.page = 1;
      this.listQuery.manufacturer = [''];
      this.listQuery.ad_type = [''];
      this.listQuery.scenario = [''];
      this.listQuery.ad_event = ['click'];
      this.listQuery.ad_name = [''];
      this.listQuery.qudao = [''];
      this.listQuery.link_id = [''];
      this.listQuery.pkg_id = [1];
      this.value1 = [(0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}'), (0, _utils.parseTime)(new Date().getTime(), '{y}-{m}-{d}')];
      this.getList();
    },
    getSummaries: function getSummaries() {
      var sums = ['总计', '-', '-', '-', '-', '-', '-', '-', '-', '-', this.count_uv, this.count_pv];
      return sums;
    },
    handleDowload: function handleDowload() {
      var _this2 = this;

      if (this.clickDownload) {
        this.clickDownload = false;
        this.clickDownloadIcon = 'el-icon-loading';
        var obj = {
          page: this.listQuery.page,
          limit: this.listQuery.limit,
          manufacturer: JSON.stringify(this.listQuery.manufacturer),
          ad_type: JSON.stringify(this.listQuery.ad_type),
          scenario: JSON.stringify(this.listQuery.scenario),
          ad_event: JSON.stringify(this.listQuery.ad_event),
          ad_name: JSON.stringify(this.listQuery.ad_name),
          qudao: JSON.stringify(this.listQuery.qudao),
          link_id: JSON.stringify(this.listQuery.link_id),
          pkg_id: JSON.stringify(this.listQuery.pkg_id),
          start_tm: this.listQuery.start_tm,
          end_tm: this.listQuery.end_tm
        };
        (0, _eventsDetail.fetchDowload)(obj).then(function (response) {
          _this2.clickDownload = true;
          _this2.clickDownloadIcon = 'el-icon-download';

          if (response.STATUS == 2000) {
            window.open(response.DATA, '_blank');
          } else {
            (0, _elementUi.Message)({
              message: '导出报表失败',
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    }
  }
};
exports.default = _default;