"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShield = getShield;
exports.setShield = setShield;
exports.fetchUser = fetchUser;
exports.updateUser = updateUser;
exports.fetchOneCache = fetchOneCache;
exports.fetchAllCache = fetchAllCache;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取黑名单地区
function getShield(data) {
  return (0, _request.default)({
    url: '/guide/getwhite',
    method: 'post',
    data: data
  });
} // 设置黑名单地区


function setShield(data) {
  return (0, _request.default)({
    url: '/guide/white',
    method: 'post',
    data: data
  });
} //获取用户白名单


function fetchUser(data) {
  return (0, _request.default)({
    url: '/guide/getuserwhite',
    method: 'post',
    data: data
  });
} //设置用户白名单


function updateUser(data) {
  return (0, _request.default)({
    url: '/guide/userwhite',
    method: 'post',
    data: data
  });
} // 单个清除缓存数据


function fetchOneCache(data) {
  return (0, _request.default)({
    url: '/device/del',
    method: 'post',
    data: data
  });
} // 全部清除缓存数据


function fetchAllCache(data) {
  return (0, _request.default)({
    url: '/device/del',
    method: 'post',
    data: data
  });
}