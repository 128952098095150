var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c("el-input", {
            staticClass: "margin_bottom",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入设备ID" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleOneCache($event)
              }
            },
            model: {
              value: _vm.temp1.android,
              callback: function($$v) {
                _vm.$set(_vm.temp1, "android", $$v)
              },
              expression: "temp1.android"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin_bottom",
              attrs: { type: "primary", icon: "el-icon-delete" },
              on: { click: _vm.handleOneCache }
            },
            [_vm._v("\n      单个清除缓存\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin_bottom btn",
              attrs: { type: "primary", icon: "el-icon-delete" },
              on: { click: _vm.handleAllCache }
            },
            [_vm._v("\n      全部清除缓存\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin_bottom btn",
              staticStyle: { width: "140px", float: "right" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.updateData()
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "20px",
            "margin-bottom": "20px"
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "row_style" }, [
              _c(
                "div",
                { staticClass: "my_label", staticStyle: { width: "180px" } },
                [_vm._v("黑名单地区(逗号分割)")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      autosize: { minRows: 6, maxRows: 12 },
                      placeholder: "请输入地区名称，多个逗号隔开"
                    },
                    model: {
                      value: _vm.temp.blackarea,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "blackarea", $$v)
                      },
                      expression: "temp.blackarea"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "20px",
            "margin-bottom": "20px"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "40px" } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "row_style" }, [
                  _c(
                    "div",
                    {
                      staticClass: "my_label",
                      staticStyle: { width: "180px" }
                    },
                    [_vm._v("不展示广告地区")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { flex: "1", "padding-right": "20px" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "填写厂商所在地，用于给厂商演示，多个逗号隔开"
                        },
                        model: {
                          value: _vm.temp.noadvarea,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "noadvarea", $$v)
                          },
                          expression: "temp.noadvarea"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "my_describ" }, [
                  _vm._v(
                    "说明：多个地区用英文逗号隔开，填写的地区将不会展示任何广告"
                  )
                ])
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "20px",
            "margin-bottom": "20px"
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "row_style" }, [
              _c(
                "div",
                { staticClass: "my_label", staticStyle: { width: "180px" } },
                [_vm._v("用户白名单(逗号分割)")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      autosize: { minRows: 6, maxRows: 12 },
                      placeholder: "请输入用户的安卓ID，多个逗号隔开"
                    },
                    model: {
                      value: _vm.userTemp.android,
                      callback: function($$v) {
                        _vm.$set(_vm.userTemp, "android", $$v)
                      },
                      expression: "userTemp.android"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }