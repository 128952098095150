"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/appadmin/index");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'configQd',
  data: function data() {
    return {
      globalInfo: null,
      timer: null,
      package: '',
      disabled: true
    };
  },
  computed: {
    cur_app_name: {
      get: function get() {
        return this.$store.state.app.cur_app_name;
      },
      set: function set() {}
    }
  },
  mounted: function mounted() {
    if (this.cur_app_name == 'cloud.quick.calendar.chuan') {
      this.package = 'cloud.quick.calendar.qudao';
      this.disabled = false;
      this.configInit();
    } else if (this.cur_app_name == 'cloud.quick.constellatory.chuan') {
      this.package = 'cloud.quick.constellatory.qudao';
      this.disabled = false;
      this.configInit();
    } else if (this.cur_app_name == 'cloud.quick.weather.chuan') {
      this.package = 'cloud.quick.weather.qudao';
      this.disabled = false;
      this.configInit();
    } else if (this.cur_app_name == 'com.shiguang.calendar.chuan') {
      this.package = 'com.shiguang.calendar.qudao';
      this.disabled = false;
      this.configInit();
    } else {
      this.disabled = true;
      this.globalInfo = {
        qudao: ''
      };
    }
  },
  methods: {
    // 初始化
    configInit: function configInit() {
      var that = this;
      that.getGlobalSetting();
      that.timer = setInterval(function () {
        (0, _index.fetchUpdateQudao)(that.package).then(function (r) {
          if (!r.DATA) {
            that.getGlobalSetting();
          }
        });
      }, 2000);
    },
    // 获取配置
    getGlobalSetting: function getGlobalSetting() {
      var that = this;
      (0, _index.getGlobalSettingQudao)(that.package).then(function (r) {
        that.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null;
      });
    },
    // 保存配置
    updateConfig: function updateConfig() {
      var _this = this;

      var that = this;
      (0, _index.setGlobalSettingQudao)(that.package, {
        jsonData: escape(JSON.stringify(this.globalInfo))
      }).then(function (r) {
        _this.getGlobalSetting();

        (0, _elementUi.Message)({
          type: "success",
          message: "操作成功"
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  }
};
exports.default = _default;