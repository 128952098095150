"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _version = require("@/api/baseControl/version");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _elementUi = require("element-ui");

var _vuex = require("vuex");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = {
  name: 'Version',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        pkg: '',
        type: '0',
        keyWord: ''
      },
      temp: {
        id: undefined,
        // pkg: '',
        num: '',
        up_info: '',
        vivo_memberCheckRpkDate: '',
        oppo_memberCheckRpkDate: '',
        xiaomi_memberCheckRpkDate: '',
        huawei_memberCheckRpkDate: ''
      },
      dialogFormVisible: false,
      dialogStatus: 'create',
      dialogWidth: 0,
      textMap: {
        update: '修改版本',
        create: '添加版本'
      },
      data: {},
      rules: {
        num: [{
          required: true,
          message: '提交审核的版本号是必需的',
          trigger: 'blur'
        }],
        up_info: [{
          required: true,
          message: '版本的迭代内容是必需的',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["cur_app_name"])),
  created: function created() {
    this.getList();
    this.setDialogWidth();
  },
  mounted: function mounted() {
    var _this = this;

    window.onresize = function () {
      return function () {
        _this.setDialogWidth();
      }();
    };
  },
  methods: {
    selectHandleFilter: function selectHandleFilter() {
      this.listQuery.keyWord = '';
      this.getList();
    },
    handleCancel: function handleCancel() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.listQuery.type = '0';
      this.listQuery.keyWord = '';
      this.getList();
    },
    dateFormat: function dateFormat(v) {
      return _moment.default.unix(v / 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    switchHandel: function switchHandel(v, row) {
      var _this2 = this;

      if (v == '0') {
        row.is_bj = 1;
      } else {
        row.is_bj = 0;
      }

      if (row.info.length == 0 || row.info.a == 'a') {
        (0, _elementUi.Message)({
          message: '数据未爬取,暂不能修改！',
          type: 'error',
          duration: 2000
        });
      } else {
        this.$confirm("\u786E\u8BA4".concat(v == 0 ? '关闭' : '开启', "\u5F00\u5173\uFF1F")).then(function (_) {
          var data = {
            id: row.id,
            is_bj: v
          };
          (0, _version.fetchOff)(data).then(function (res) {
            if (res.STATUS == '2000') {
              (0, _elementUi.Message)({
                message: '更新成功',
                type: 'success',
                duration: 3 * 1000
              });
            } else {
              (0, _elementUi.Message)({
                message: '更新失败',
                type: 'error',
                duration: 3 * 1000
              });
            }

            _this2.getList();
          });
        }).catch(function (_) {});
      }
    },
    setDialogWidth: function setDialogWidth() {
      var val = document.body.clientWidth;
      var def = 600; // 默认宽度

      if (val < def) {
        this.dialogWidth = '100%';
      } else {
        this.dialogWidth = def + 'px';
      }
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      this.listQuery.pkg = this.cur_app_name;
      var data = (0, _objectSpread2.default)({}, this.listQuery);

      if (this.listQuery.type == '0') {
        data.type = 'num';
        data.num = this.listQuery.keyWord;
        delete data.keyWord;
      } else {
        data.type = 'up_info';
        data.up_info = this.listQuery.keyWord;
        delete data.keyWord;
      }

      (0, _version.fetchList)(data).then(function (response) {
        _this3.listLoading = false;

        if (response.STATUS == 2000) {
          _this3.list = response.DATA.items;
          _this3.total = response.DATA.totalSize;
        } else {
          (0, _elementUi.Message)({
            message: response.DEC || '获取版本信息失败',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: '',
        pkg: this.cur_app_name,
        num: '',
        up_info: '',
        vivo_memberCheckRpkDate: '',
        oppo_memberCheckRpkDate: '',
        xiaomi_memberCheckRpkDate: '',
        huawei_memberCheckRpkDate: ''
      };
    },
    createShow: function createShow() {
      var _this4 = this;

      this.resetTemp();
      this.dialogStatus = 'create';
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
      this.dialogFormVisible = true;
    },
    postCreate: function postCreate() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var obj = {
            pkg: _this5.temp.pkg,
            num: _this5.temp.num,
            up_info: _this5.temp.up_info
          };
          (0, _version.fetchAdd)(obj).then(function (response) {
            if (response.STATUS == 2000) {
              _this5.handleFilter();

              _this5.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: '添加版本信息成功',
                type: 'success',
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: '添加版本信息失败，请重试',
                type: 'error',
                duration: 2000
              });
            }
          });
        }
      });
    },
    updateShow: function updateShow(row) {
      console.log(row);

      if (row.info.length == 0 || row.info.a == 'a') {
        this.data = row;
        this.temp.id = row.id;
        this.temp.num = row.num;
        this.temp.up_info = row.up_info;
        this.temp.vivo_memberCheckRpkDate = '';
        this.temp.oppo_memberCheckRpkDate = '';
        this.temp.xiaomi_memberCheckRpkDate = '';
        this.temp.huawei_memberCheckRpkDate = '';
        this.dialogStatus = 'update';
        this.dialogFormVisible = true; // Message({
        //   message: '数据未爬取,暂不能修改！',
        //   type: 'error',
        //   duration: 2000
        // })
      } else {
        this.data = row;
        this.temp.id = row.id;
        this.temp.num = row.num;
        this.temp.up_info = row.up_info;
        this.temp.vivo_memberCheckRpkDate = row.info.vivo.status == 2 && row.info.vivo.memberCheckRpkDate && _moment.default.unix(row.info.vivo.memberCheckRpkDate / 1000).format('YYYY-MM-DD HH:mm:ss');
        this.temp.oppo_memberCheckRpkDate = row.info.OPPO.status == 2 && row.info.OPPO.memberCheckRpkDate && _moment.default.unix(row.info.OPPO.memberCheckRpkDate / 1000).format('YYYY-MM-DD HH:mm:ss');
        this.temp.xiaomi_memberCheckRpkDate = row.info['小米'].status == 2 && row.info['小米'].memberCheckRpkDate && _moment.default.unix(row.info['小米'].memberCheckRpkDate / 1000).format('YYYY-MM-DD HH:mm:ss');
        this.temp.huawei_memberCheckRpkDate = row.info['华为'].status == 2 && row.info['华为'].memberCheckRpkDate && _moment.default.unix(row.info['华为'].memberCheckRpkDate / 1000).format('YYYY-MM-DD HH:mm:ss');
        this.dialogStatus = 'update';
        this.dialogFormVisible = true;
      }
    },
    postUpdate: function postUpdate() {
      var _this6 = this;

      var data = JSON.parse(JSON.stringify(this.temp));

      if (this.data.info.length != 0 && this.data.info.a != 'a') {
        this.data.info.vivo.memberCheckRpkDate = "".concat(new Date(data.vivo_memberCheckRpkDate).getTime());
        this.data.info.OPPO.memberCheckRpkDate = "".concat(new Date(data.oppo_memberCheckRpkDate).getTime());
        this.data.info['小米'].memberCheckRpkDate = "".concat(new Date(data.xiaomi_memberCheckRpkDate).getTime());
        this.data.info['华为'].memberCheckRpkDate = "".concat(new Date(data.huawei_memberCheckRpkDate).getTime());
      }

      this.data.up_info = this.temp.up_info;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _version.fetchUpdate)(_this6.data).then(function (response) {
            if (response.STATUS == 2000) {
              _this6.getList();

              _this6.dialogFormVisible = false;
              (0, _elementUi.Message)({
                message: '修改版本信息成功',
                type: 'success',
                duration: 2000
              });
            } else {
              (0, _elementUi.Message)({
                message: '修改版本信息失败，请重试',
                type: 'error',
                duration: 2000
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;