var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "margin_bottom btn",
              staticStyle: { width: "140px", float: "right" },
              attrs: { type: "primary", disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.updateConfig()
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "row_style" }, [
                  _c(
                    "div",
                    {
                      staticClass: "my_label",
                      staticStyle: { width: "180px" }
                    },
                    [_vm._v("指定配置生效渠道：")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          disabled: _vm.disabled,
                          autosize: { minRows: 6, maxRows: 12 },
                          placeholder: "请输入配置生效的渠道，多个逗号隔开"
                        },
                        model: {
                          value: _vm.globalInfo.qudao,
                          callback: function($$v) {
                            _vm.$set(_vm.globalInfo, "qudao", $$v)
                          },
                          expression: "globalInfo.qudao"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }