"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, // {
//   path: '/',
//   component: Layout,
//   redirect: '/baseControl',
//   breadcrumb: false, 
// },
{
  path: '/',
  component: _layout.default,
  redirect: '/advConfig',
  breadcrumb: false,
  meta: {
    title: '基本配置',
    icon: 'dashboard'
  },
  children: [{
    path: 'redirect',
    name: 'redirect',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/redirect'));
      });
    },
    meta: {
      title: '包名切换',
      icon: 'dashboard'
    }
  }, {
    path: 'configQd',
    name: 'configQd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/configQd'));
      });
    },
    meta: {
      title: '指定渠道',
      icon: 'dashboard'
    }
  }, {
    path: 'advConfig',
    name: 'advConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/adControl/advConfig'));
      });
    },
    meta: {
      title: '广告管理',
      icon: 'dashboard'
    }
  }, {
    path: 'examineControl',
    name: 'examineControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/examineControl'));
      });
    },
    meta: {
      title: '审核模式',
      icon: 'dashboard'
    }
  }, {
    path: 'businessControl',
    name: 'businessControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/businessControl'));
      });
    },
    meta: {
      title: '业务配置',
      icon: 'dashboard'
    }
  }, {
    path: 'activityControl',
    name: 'activityControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/activityControl'));
      });
    },
    meta: {
      title: '活动配置',
      icon: 'dashboard'
    }
  }, {
    path: 'linkset',
    name: 'linkset',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/linkset'));
      });
    },
    meta: {
      title: '链接配置',
      icon: 'dashboard'
    }
  }, {
    path: 'userAgreement',
    name: 'userAgreement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/userAgreement'));
      });
    },
    meta: {
      title: '隐私协议',
      icon: 'dashboard'
    }
  }, {
    path: 'appletSetting',
    name: 'appletSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/appletSetting'));
      });
    },
    meta: {
      title: '小程序配置',
      icon: 'dashboard'
    }
  }, {
    path: 'verifyFile',
    name: 'verifyFile',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/verifyFile'));
      });
    },
    meta: {
      title: '校验文件',
      icon: 'dashboard'
    }
  }, {
    path: 'version',
    name: 'Version',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/version'));
      });
    },
    meta: {
      title: '版本信息',
      icon: 'dashboard'
    }
  }, {
    path: 'debug',
    name: 'debug',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/baseControl/debug'));
      });
    },
    meta: {
      title: '联调配置',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/shieldControl',
  component: _layout.default,
  meta: {
    title: '全局管理',
    icon: 'dashboard'
  },
  children: [{
    path: 'shield',
    name: 'shield',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shieldControl/shield'));
      });
    },
    meta: {
      title: '屏蔽配置',
      icon: 'dashboard'
    }
  }, {
    path: 'report',
    name: 'report',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shieldControl/report'));
      });
    },
    meta: {
      title: '设备报表',
      icon: 'dashboard'
    }
  }, {
    path: 'baiduCdn',
    name: 'BaiduCdn',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shieldControl/baiduCdn'));
      });
    },
    meta: {
      title: '百度CDN',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/advEvent',
  component: _layout.default,
  meta: {
    title: '广告监测',
    icon: 'dashboard'
  },
  children: [{
    path: 'customEvents',
    name: 'CustomEvents',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/advEvent/customEvents'));
      });
    },
    meta: {
      title: '定义事件',
      icon: 'dashboard'
    }
  }, {
    path: 'eventsDetail',
    name: 'EventsDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/advEvent/eventsDetail'));
      });
    },
    meta: {
      title: '事件报表',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/feedback',
  component: _layout.default,
  meta: {
    title: '投诉监测',
    icon: 'dashboard'
  },
  children: [{
    path: 'list',
    name: 'List',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/list'));
      });
    },
    meta: {
      title: '投诉列表',
      icon: 'dashboard'
    }
  }, // {
  //   path: 'quickList',
  //   name: 'QuickList',
  //   component: () => import('@/views/feedback/quickList'),
  //   meta: { title: '应用投诉', icon: 'dashboard' }
  // },
  {
    path: 'feedbackRate',
    name: 'FeedbackRate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/feedbackRate'));
      });
    },
    meta: {
      title: '投诉比率',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/btn',
  component: _layout.default,
  meta: {
    title: '按钮投诉',
    icon: 'dashboard'
  },
  children: [{
    path: 'btnList',
    name: 'BtnList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/btnList'));
      });
    },
    meta: {
      title: '按钮投诉列表',
      icon: 'dashboard'
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;