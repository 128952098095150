var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.adConfigData
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.adConfigData, "label-width": "200px" }
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基础配置", name: "1" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启app唤醒广告优化" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.pullAppOpSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "pullAppOpSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.pullAppOpSwitch"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启华为广告10秒下载" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.huaweiAutoDowload,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "huaweiAutoDowload",
                                  $$v
                                )
                              },
                              expression: "adConfigData.huaweiAutoDowload"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "华为自动下载按钮延迟时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，单位为毫秒"
                            },
                            model: {
                              value: _vm.adConfigData.autoDowloadBtnShowTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "autoDowloadBtnShowTime",
                                  $$v
                                )
                              },
                              expression: "adConfigData.autoDowloadBtnShowTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "广告弹窗关闭按钮延迟时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，单位为毫秒"
                            },
                            model: {
                              value: _vm.adConfigData.adPopCloseBtnShowTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "adPopCloseBtnShowTime",
                                  $$v
                                )
                              },
                              expression: "adConfigData.adPopCloseBtnShowTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "广告弹窗广告图片延迟时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，单位为毫秒"
                            },
                            model: {
                              value: _vm.adConfigData.adPopImageShowTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "adPopImageShowTime",
                                  $$v
                                )
                              },
                              expression: "adConfigData.adPopImageShowTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("活动页红包弹窗样式配置")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { height: "214px" },
                        attrs: {
                          src: require("../../assets/red_pop_style.png"),
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "0px" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 1 },
                              model: {
                                value:
                                  _vm.adConfigData.backLeavePopSetting
                                    .otherEnterRedPopStyle,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.adConfigData.backLeavePopSetting,
                                    "otherEnterRedPopStyle",
                                    $$v
                                  )
                                },
                                expression:
                                  "adConfigData.backLeavePopSetting.otherEnterRedPopStyle"
                              }
                            },
                            [
                              _c("el-checkbox", { attrs: { label: 1 } }, [
                                _vm._v("样式1")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("活动页退出弹窗样式配置")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { width: "1140px" },
                        attrs: {
                          src: require("../../assets/back_pop_style.png"),
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "0px" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 1 },
                              model: {
                                value:
                                  _vm.adConfigData.backLeavePopSetting
                                    .otherEnterBackPopStyle,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.adConfigData.backLeavePopSetting,
                                    "otherEnterBackPopStyle",
                                    $$v
                                  )
                                },
                                expression:
                                  "adConfigData.backLeavePopSetting.otherEnterBackPopStyle"
                              }
                            },
                            [
                              _c("el-checkbox", { attrs: { label: 1 } }, [
                                _vm._v("样式1")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 2 } }, [
                                _vm._v("样式2")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 3 } }, [
                                _vm._v("样式3")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 4 } }, [
                                _vm._v("样式4")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 5 } }, [
                                _vm._v("样式5")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 6 } }, [
                                _vm._v("样式6")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 7 } }, [
                                _vm._v("样式7")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 8 } }, [
                                _vm._v("样式8")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 9 } }, [
                                _vm._v("样式9")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 10 } }, [
                                _vm._v("样式10")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("首页退出弹窗样式配置")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { width: "1140px" },
                        attrs: {
                          src: require("../../assets/back_pop_style.png"),
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "0px" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 1 },
                              model: {
                                value:
                                  _vm.adConfigData.backLeavePopSetting
                                    .otherEnterLeavePopStyle,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.adConfigData.backLeavePopSetting,
                                    "otherEnterLeavePopStyle",
                                    $$v
                                  )
                                },
                                expression:
                                  "adConfigData.backLeavePopSetting.otherEnterLeavePopStyle"
                              }
                            },
                            [
                              _c("el-checkbox", { attrs: { label: 1 } }, [
                                _vm._v("样式1")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 2 } }, [
                                _vm._v("样式2")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 3 } }, [
                                _vm._v("样式3")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 4 } }, [
                                _vm._v("样式4")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 5 } }, [
                                _vm._v("样式5")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 6 } }, [
                                _vm._v("样式6")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 7 } }, [
                                _vm._v("样式7")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 8 } }, [
                                _vm._v("样式8")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 9 } }, [
                                _vm._v("样式9")
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: 10 } }, [
                                _vm._v("样式10")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("活动页红包弹窗配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启活动页红包弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.showLeaveSwitchSecond,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "showLeaveSwitchSecond",
                                  $$v
                                )
                              },
                              expression: "adConfigData.showLeaveSwitchSecond"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("（关闭则没有活动页红包弹窗）")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "VIVO是否开启后退弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.vivoShowLeaveSwitchSecond,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "vivoShowLeaveSwitchSecond",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.vivoShowLeaveSwitchSecond"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("（关闭则VIVO没有活动页红包弹窗）")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "后退弹窗的展示间隔" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，可不填，不可为0"
                            },
                            model: {
                              value: _vm.adConfigData.showLeaveTimeSecond,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "showLeaveTimeSecond",
                                  $$v
                                )
                              },
                              expression: "adConfigData.showLeaveTimeSecond"
                            }
                          }),
                          _vm._v("\n            秒\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("活动页退出弹窗配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启活动页退出弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.showLeaveSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "showLeaveSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.showLeaveSwitch"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("（关闭则没有活动页退出弹窗）")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "VIVO是否开启后退弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.vivoShowLeaveSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "vivoShowLeaveSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.vivoShowLeaveSwitch"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("（关闭则VIVO没有活动页退出弹窗）")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "后退弹窗的展示间隔" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，可不填，不可为0"
                            },
                            model: {
                              value: _vm.adConfigData.showLeaveTime,
                              callback: function($$v) {
                                _vm.$set(_vm.adConfigData, "showLeaveTime", $$v)
                              },
                              expression: "adConfigData.showLeaveTime"
                            }
                          }),
                          _vm._v("\n            秒\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("首页退出弹窗配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启首页退出弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.homeLeaveSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "homeLeaveSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.homeLeaveSwitch"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("（关闭则没有首页退出弹窗）")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "VIVO是否开启退出弹窗" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.vivoHomeLeaveSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "vivoHomeLeaveSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.vivoHomeLeaveSwitch"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("（关闭则VIVO没有首页退出弹窗）")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "首页退出弹窗的展示间隔" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，可不填，不可为0"
                            },
                            model: {
                              value: _vm.adConfigData.homeLeaveTime,
                              callback: function($$v) {
                                _vm.$set(_vm.adConfigData, "homeLeaveTime", $$v)
                              },
                              expression: "adConfigData.homeLeaveTime"
                            }
                          }),
                          _vm._v("\n            秒\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "60px 0px 20px 0px" } },
                        [_vm._v("用户点击广告后再展示间隔配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户点击广告" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，可不填，不可为0"
                            },
                            model: {
                              value: _vm.adConfigData.clickedAdvNum,
                              callback: function($$v) {
                                _vm.$set(_vm.adConfigData, "clickedAdvNum", $$v)
                              },
                              expression: "adConfigData.clickedAdvNum"
                            }
                          }),
                          _vm._v("\n            次\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "下次再展示弹窗广告的间隔" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              placeholder: "填写纯数字即可，可不填，不可为0"
                            },
                            model: {
                              value: _vm.adConfigData.clickedAdvTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData,
                                  "clickedAdvTime",
                                  $$v
                                )
                              },
                              expression: "adConfigData.clickedAdvTime"
                            }
                          }),
                          _vm._v("\n            秒\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "文案配置", name: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "0px 0px 20px 0px" } },
                        [_vm._v("活动页红包弹窗文案配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.redPop1Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "redPop1Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.redPop1Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.redPop1Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "redPop1Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.redPop1Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "0px 0px 20px 0px" } },
                        [_vm._v("活动页退出弹窗文案配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop1Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop1Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop1Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop1Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop1Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop1Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式2弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop2Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop2Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop2Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式2弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop2Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop2Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop2Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式3弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop3Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop3Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop3Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式3弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop3Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop3Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop3Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式4弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop4Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop4Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop4Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式4弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop4Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop4Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop4Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式5弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop5Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop5Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop5Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式5弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop5Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop5Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop5Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式6弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop6Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop6Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop6Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式6弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop6Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop6Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop6Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式7弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop7Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop7Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop7Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式7弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop7Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop7Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop7Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式8弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop8Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop8Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop8Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式8弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop8Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop8Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop8Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式9弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop9Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop9Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop9Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式9弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop9Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop9Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop9Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式10弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop10Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop10Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop10Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式10弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.backPop10Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "backPop10Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.backPop10Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { margin: "0px 0px 20px 0px" } },
                        [_vm._v("首页退出弹窗文案配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop1Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop1Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop1Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式1弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop1Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop1Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop1Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式2弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop2Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop2Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop2Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式2弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop2Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop2Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop2Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式3弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop3Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop3Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop3Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式3弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop3Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop3Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop3Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式4弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop4Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop4Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop4Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式4弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop4Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop4Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop4Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式5弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop5Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop5Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop5Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式5弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop5Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop5Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop5Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式6弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop6Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop6Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop6Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式6弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop6Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop6Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop6Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式7弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop7Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop7Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop7Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式7弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop7Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop7Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop7Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式8弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop8Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop8Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop8Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式8弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop8Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop8Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop8Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式9弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop9Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop9Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop9Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式9弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop9Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop9Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop9Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式10弹窗描述",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop10Des,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop10Des",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop10Des"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "样式10弹窗提示",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "230px" },
                                    attrs: {
                                      placeholder: "请填写文案",
                                      disabled: _vm.disabled
                                    },
                                    model: {
                                      value:
                                        _vm.adConfigData.backLeavePopSetting
                                          .popDescrib.leavePop10Tips,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.adConfigData.backLeavePopSetting
                                            .popDescrib,
                                          "leavePop10Tips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adConfigData.backLeavePopSetting.popDescrib.leavePop10Tips"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "补量配置", name: "4" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "补量开关", "label-width": "250px" }
                        },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.hideBl.hideBlSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl,
                                  "hideBlSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.hideBl.hideBlSwitch"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否循环补量开关",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.adConfigData.hideBl.hideBlLoopSwitch,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl,
                                  "hideBlLoopSwitch",
                                  $$v
                                )
                              },
                              expression: "adConfigData.hideBl.hideBlLoopSwitch"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "第一次进行补量时的时间区间",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            model: {
                              value:
                                _vm.adConfigData.hideBl.hideBlFirstTime.minTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl.hideBlFirstTime,
                                  "minTime",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.hideBl.hideBlFirstTime.minTime"
                            }
                          }),
                          _vm._v(" ~\n            "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            model: {
                              value:
                                _vm.adConfigData.hideBl.hideBlFirstTime.maxTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl.hideBlFirstTime,
                                  "maxTime",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.hideBl.hideBlFirstTime.maxTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "循环补量递增的间隔时间",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            model: {
                              value:
                                _vm.adConfigData.hideBl.hideBlSpaceTiem.minTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl.hideBlSpaceTiem,
                                  "minTime",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.hideBl.hideBlSpaceTiem.minTime"
                            }
                          }),
                          _vm._v(" ~\n            "),
                          _c("el-input", {
                            staticStyle: { width: "50px" },
                            model: {
                              value:
                                _vm.adConfigData.hideBl.hideBlSpaceTiem.maxTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.hideBl.hideBlSpaceTiem,
                                  "maxTime",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.hideBl.hideBlSpaceTiem.maxTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "华为后退离开广告位轮播个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.huawei,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "huawei",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.huawei"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "华为一次补量广告个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.huawei_bl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "huawei_bl",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.huawei_bl"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "vivo后退离开广告位轮播个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.vivo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "vivo",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.vivo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "vivo一次补量广告个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.vivo_bl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "vivo_bl",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.vivo_bl"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "oppo后退离开广告位轮播个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.oppo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "oppo",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.oppo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "oppo一次补量广告个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.oppo_bl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "oppo_bl",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.oppo_bl"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "小米后退离开广告位轮播个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.xiaomi,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "xiaomi",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.xiaomi"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "小米一次补量广告个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.xiaomi_bl,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "xiaomi_bl",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.xiaomi_bl"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "其他手机后退离开广告位轮播个数",
                            "label-width": "250px"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "116px" },
                            model: {
                              value: _vm.globalInfo.backBannerNum.other,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo.backBannerNum,
                                  "other",
                                  $$v
                                )
                              },
                              expression: "globalInfo.backBannerNum.other"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "广告权重配置", name: "5" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "30px", margin: "50px" }
                        },
                        [_vm._v("OPPO厂商权重配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "1000px"
                          }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                                background: "#eee"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("广告类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("是否开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("权重")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.adConfigData.advWeightNew.oppo, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.advName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.oppo[
                                            index
                                          ].switch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew.oppo[
                                              index
                                            ],
                                            "switch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.oppo[index].switch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "text-align": "center"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChangeAdvWeightNew(
                                            "oppo"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.oppo[
                                            index
                                          ].weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew.oppo[
                                              index
                                            ],
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.oppo[index].weight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "30px", margin: "50px" }
                        },
                        [_vm._v("vivo厂商权重配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "1000px"
                          }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                                background: "#eee"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("广告类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("是否开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("权重")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.adConfigData.advWeightNew.vivo, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.advName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.vivo[
                                            index
                                          ].switch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew.vivo[
                                              index
                                            ],
                                            "switch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.vivo[index].switch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "text-align": "center"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChangeAdvWeightNew(
                                            "vivo"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.vivo[
                                            index
                                          ].weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew.vivo[
                                              index
                                            ],
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.vivo[index].weight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "30px", margin: "50px" }
                        },
                        [_vm._v("华为厂商权重配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "1000px"
                          }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                                background: "#eee"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("广告类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("是否开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("权重")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.adConfigData.advWeightNew.huawei, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.advName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.huawei[
                                            index
                                          ].switch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew
                                              .huawei[index],
                                            "switch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.huawei[index].switch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "text-align": "center"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChangeAdvWeightNew(
                                            "huawei"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.huawei[
                                            index
                                          ].weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew
                                              .huawei[index],
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.huawei[index].weight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "30px", margin: "50px" }
                        },
                        [_vm._v("小米厂商权重配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "1000px"
                          }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                                background: "#eee"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("广告类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("是否开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("权重")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.adConfigData.advWeightNew.xiaomi, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.advName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.xiaomi[
                                            index
                                          ].switch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew
                                              .xiaomi[index],
                                            "switch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.xiaomi[index].switch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "text-align": "center"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChangeAdvWeightNew(
                                            "xiaomi"
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeightNew.xiaomi[
                                            index
                                          ].weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeightNew
                                              .xiaomi[index],
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeightNew.xiaomi[index].weight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "font-size": "30px", margin: "50px" }
                        },
                        [_vm._v("其他厂商权重配置：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            width: "1000px"
                          }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                                background: "#eee"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("广告类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("是否开启")]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "center"
                                  }
                                },
                                [_vm._v("权重")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.adConfigData.advWeight, function(
                            item,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.advName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeight[index]
                                            .switch,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeight[index],
                                            "switch",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeight[index].switch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "text-align": "center"
                                      },
                                      on: { change: _vm.onChangeAdvWeight },
                                      model: {
                                        value:
                                          _vm.adConfigData.advWeight[index]
                                            .weight,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.advWeight[index],
                                            "weight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.advWeight[index].weight"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "App广告配置", name: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("同步配置")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.addSearch()
                                }
                              }
                            },
                            [_vm._v("添加App")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.adConfigData.pullAppList }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.pullLinkList[0]
                                        ? _c(
                                            "div",
                                            { staticClass: "link-box" },
                                            _vm._l(
                                              scope.row.pullLinkList,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "link-item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "link-title"
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "120px"
                                                                },
                                                                attrs: {
                                                                  slot:
                                                                    "content"
                                                                },
                                                                slot: "content"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "success"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "广告名称: " +
                                                                    _vm._s(
                                                                      item.title
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "link-url"
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "750px"
                                                                },
                                                                attrs: {
                                                                  slot:
                                                                    "content"
                                                                },
                                                                slot: "content"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.url
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "success"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "拉起链接: " +
                                                                    _vm._s(
                                                                      item.url
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "link-url"
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "750px"
                                                                },
                                                                attrs: {
                                                                  slot:
                                                                    "content"
                                                                },
                                                                slot: "content"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.materialUrl
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-tag",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "success"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "素材地址: " +
                                                                    _vm._s(
                                                                      item.materialUrl
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "warning",
                                                          size: "small"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onEditLink(
                                                              scope.row,
                                                              item.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("编辑")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          size: "small"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onDelLink(
                                                              scope.row,
                                                              item.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("删除")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "none-data" },
                                            [
                                              _c("span", [
                                                _vm._v("暂无更多数据")
                                              ])
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3209619001
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "名称",
                              width: "180",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "packageName", label: "包名" }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: "权重",
                              width: "90",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "onlineTime",
                              label: "上线时间",
                              width: "200",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.onlineTime
                                              ? scope.row.onlineTime
                                              : "未设置"
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2762135769
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "offlineTime",
                              label: "下线时间",
                              width: "200",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.offlineTime
                                              ? scope.row.offlineTime
                                              : "未设置"
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              236519097
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "okSkipSwitch",
                              label: "点好跳转",
                              width: "100",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.okSkipSwitch,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "okSkipSwitch",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.okSkipSwitch"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2327418560
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "switch",
                              label: "状态",
                              width: "100",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: scope.row.switch,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "switch", $$v)
                                          },
                                          expression: "scope.row.switch"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2109056997
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "250" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAddLink(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("添加链接")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onEdit(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onDel(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4095013888
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "web广告位配置", name: "7" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.showWebModal()
                                }
                              }
                            },
                            [_vm._v("添加")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          attrs: { data: _vm.adConfigData.insertScreenAdvList }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: "标题",
                              "min-width": "60px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.adConfigData
                                              .insertScreenAdvList[scope.$index]
                                              .title
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              742860651
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "materialUrl",
                              label: "图片地址",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.adConfigData
                                              .insertScreenAdvList[scope.$index]
                                              .materialUrl
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              328995211
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "targetUrl",
                              label: "跳转模板路径",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.adConfigData
                                              .insertScreenAdvList[scope.$index]
                                              .targetUrl
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2464034929
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "changshang",
                              label: "投放厂商",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.adConfigData
                                              .insertScreenAdvList[scope.$index]
                                              .changshang &&
                                              _vm.adConfigData.insertScreenAdvList[
                                                scope.$index
                                              ].changshang.toString()
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2120267399
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "switch",
                              label: "开关",
                              "min-width": "60px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.adConfigData
                                              .insertScreenAdvList[scope.$index]
                                              .switch,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData
                                                .insertScreenAdvList[
                                                scope.$index
                                              ],
                                              "switch",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.insertScreenAdvList[scope.$index].switch"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3324916638
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "switch",
                              label: "操作",
                              "min-width": "100px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showWebModal(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onDelWeb(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3388079264
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "广告点击率配置", name: "8" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("OPPO厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定横幅广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.oppo.bannerId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.oppo,
                                  "bannerId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.oppo.bannerId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定插屏广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.oppo.insertId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.oppo,
                                  "insertId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.oppo.insertId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定视频广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.oppo.videoId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.oppo,
                                  "videoId",
                                  $$v
                                )
                              },
                              expression: "adConfigData.changshang.oppo.videoId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "190px",
                                "text-align": "right",
                                "margin-right": "10px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700"
                              }
                            },
                            [
                              _vm._v(
                                "\n              随机原生广告位数组\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.adConfigData.changshang.oppo.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNativeId("oppo")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.adConfigData.changshang.oppo.randomNativeId,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "justify-content": "flex-start",
                                        display: "flex",
                                        "align-items": "center",
                                        margin: "15px 0"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.oppo
                                              .randomNativeId[index].id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.oppo
                                                .randomNativeId[index],
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.oppo.randomNativeId[index].id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "50px",
                                            "margin-right": "15px"
                                          }
                                        },
                                        [_vm._v("目标点击率")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.oppo
                                              .randomNativeId[index].minRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.oppo
                                                .randomNativeId[index],
                                              "minRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.oppo.randomNativeId[index].minRate"
                                        }
                                      }),
                                      _vm._v(" ~\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.oppo
                                              .randomNativeId[index].maxRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.oppo
                                                .randomNativeId[index],
                                              "maxRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.oppo.randomNativeId[index].maxRate"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.adConfigData.changshang.oppo
                                        .randomNativeId.length > 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delNativeId(
                                                    "oppo",
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index ==
                                      _vm.adConfigData.changshang.oppo
                                        .randomNativeId.length -
                                        1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNativeId("oppo")
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("VIVO厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定横幅广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.vivo.bannerId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.vivo,
                                  "bannerId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.vivo.bannerId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定插屏广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.vivo.insertId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.vivo,
                                  "insertId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.vivo.insertId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定视频广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.vivo.videoId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.vivo,
                                  "videoId",
                                  $$v
                                )
                              },
                              expression: "adConfigData.changshang.vivo.videoId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "190px",
                                "text-align": "right",
                                "margin-right": "10px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700"
                              }
                            },
                            [
                              _vm._v(
                                "\n              随机原生广告位数组\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.adConfigData.changshang.vivo.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNativeId("vivo")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.adConfigData.changshang.vivo.randomNativeId,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "justify-content": "flex-start",
                                        display: "flex",
                                        "align-items": "center",
                                        margin: "15px 0"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.vivo
                                              .randomNativeId[index].id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.vivo
                                                .randomNativeId[index],
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.vivo.randomNativeId[index].id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "50px",
                                            "margin-right": "15px"
                                          }
                                        },
                                        [_vm._v("目标点击率")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.vivo
                                              .randomNativeId[index].minRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.vivo
                                                .randomNativeId[index],
                                              "minRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.vivo.randomNativeId[index].minRate"
                                        }
                                      }),
                                      _vm._v(" ~\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.vivo
                                              .randomNativeId[index].maxRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.vivo
                                                .randomNativeId[index],
                                              "maxRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.vivo.randomNativeId[index].maxRate"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.adConfigData.changshang.vivo
                                        .randomNativeId.length > 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delNativeId(
                                                    "vivo",
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index ==
                                      _vm.adConfigData.changshang.vivo
                                        .randomNativeId.length -
                                        1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNativeId("vivo")
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("小米厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定横幅广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value:
                                _vm.adConfigData.changshang.xiaomi.bannerId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.xiaomi,
                                  "bannerId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.xiaomi.bannerId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定插屏广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value:
                                _vm.adConfigData.changshang.xiaomi.insertId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.xiaomi,
                                  "insertId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.xiaomi.insertId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定视频广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.xiaomi.videoId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.xiaomi,
                                  "videoId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.xiaomi.videoId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "190px",
                                "text-align": "right",
                                "margin-right": "10px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700"
                              }
                            },
                            [
                              _vm._v(
                                "\n              随机原生广告位数组\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.adConfigData.changshang.xiaomi.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNativeId("xiaomi")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.adConfigData.changshang.xiaomi
                                  .randomNativeId,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "justify-content": "flex-start",
                                        display: "flex",
                                        "align-items": "center",
                                        margin: "15px 0"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.xiaomi
                                              .randomNativeId[index].id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.xiaomi
                                                .randomNativeId[index],
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.xiaomi.randomNativeId[index].id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "50px",
                                            "margin-right": "15px"
                                          }
                                        },
                                        [_vm._v("目标点击率")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.xiaomi
                                              .randomNativeId[index].minRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.xiaomi
                                                .randomNativeId[index],
                                              "minRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.xiaomi.randomNativeId[index].minRate"
                                        }
                                      }),
                                      _vm._v(" ~\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.xiaomi
                                              .randomNativeId[index].maxRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.xiaomi
                                                .randomNativeId[index],
                                              "maxRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.xiaomi.randomNativeId[index].maxRate"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.adConfigData.changshang.xiaomi
                                        .randomNativeId.length > 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delNativeId(
                                                    "xiaomi",
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index ==
                                      _vm.adConfigData.changshang.xiaomi
                                        .randomNativeId.length -
                                        1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNativeId(
                                                    "xiaomi"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("华为厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定横幅广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value:
                                _vm.adConfigData.changshang.huawei.bannerId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.huawei,
                                  "bannerId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.huawei.bannerId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定插屏广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value:
                                _vm.adConfigData.changshang.huawei.insertId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.huawei,
                                  "insertId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.huawei.insertId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定视频广告位id" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.adConfigData.changshang.huawei.videoId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.adConfigData.changshang.huawei,
                                  "videoId",
                                  $$v
                                )
                              },
                              expression:
                                "adConfigData.changshang.huawei.videoId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "190px",
                                "text-align": "right",
                                "margin-right": "10px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700"
                              }
                            },
                            [
                              _vm._v(
                                "\n              随机原生广告位数组\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.adConfigData.changshang.huawei.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNativeId("huawei")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.adConfigData.changshang.huawei
                                  .randomNativeId,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "justify-content": "flex-start",
                                        display: "flex",
                                        "align-items": "center",
                                        margin: "15px 0"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.huawei
                                              .randomNativeId[index].id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.huawei
                                                .randomNativeId[index],
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.huawei.randomNativeId[index].id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "50px",
                                            "margin-right": "15px"
                                          }
                                        },
                                        [_vm._v("目标点击率")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.huawei
                                              .randomNativeId[index].minRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.huawei
                                                .randomNativeId[index],
                                              "minRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.huawei.randomNativeId[index].minRate"
                                        }
                                      }),
                                      _vm._v(" ~\n                "),
                                      _c("el-input", {
                                        staticStyle: { width: "100px" },
                                        model: {
                                          value:
                                            _vm.adConfigData.changshang.huawei
                                              .randomNativeId[index].maxRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.adConfigData.changshang.huawei
                                                .randomNativeId[index],
                                              "maxRate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "adConfigData.changshang.huawei.randomNativeId[index].maxRate"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.adConfigData.changshang.huawei
                                        .randomNativeId.length > 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delNativeId(
                                                    "huawei",
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index ==
                                      _vm.adConfigData.changshang.huawei
                                        .randomNativeId.length -
                                        1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px"
                                              },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNativeId(
                                                    "huawei"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("添加")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "厂商广告配置", name: "9" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-bottom": "15px",
                                width: "150px"
                              },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmitAdConfigSetting }
                            },
                            [_vm._v("提交")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("OPPO厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定原生广告id" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "多个请用英文逗号“,”隔开"
                                },
                                model: {
                                  value:
                                    _vm.adConfigData.changshangnew.oppo
                                      .nativeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.adConfigData.changshangnew.oppo,
                                      "nativeId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "adConfigData.changshangnew.oppo.nativeId"
                                }
                              }),
                              _vm._v(" "),
                              _vm.adConfigData.changshangnew.oppo.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addLadderNativeId("oppo")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "200px",
                                "text-align": "right",
                                "padding-right": "12px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                                "flex-shrink": "0"
                              }
                            },
                            [
                              _vm._v(
                                "\n              阶梯原生广告id\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            _vm._l(
                              _vm.adConfigData.changshangnew.oppo
                                .randomNativeId,
                              function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      display: "flex",
                                      "align-items": "center",
                                      margin: "15px 0",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                阶梯权重：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "100px",
                                        margin: "0 5px",
                                        "flex-shrink": "0"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.oppo
                                            .randomNativeId[index].num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew.oppo
                                              .randomNativeId[index],
                                            "num",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.oppo.randomNativeId[index].num"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                原生广告ID：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        placeholder: "多个请用英文逗号“,”隔开"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.oppo
                                            .randomNativeId[index].id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew.oppo
                                              .randomNativeId[index],
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.oppo.randomNativeId[index].id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.adConfigData.changshangnew.oppo
                                      .randomNativeId.length > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delLadderNativeId(
                                                  "oppo",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index ==
                                    _vm.adConfigData.changshangnew.oppo
                                      .randomNativeId.length -
                                      1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLadderNativeId(
                                                  "oppo"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("VIVO厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定原生广告id" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "多个请用英文逗号“,”隔开"
                                },
                                model: {
                                  value:
                                    _vm.adConfigData.changshangnew.vivo
                                      .nativeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.adConfigData.changshangnew.vivo,
                                      "nativeId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "adConfigData.changshangnew.vivo.nativeId"
                                }
                              }),
                              _vm._v(" "),
                              _vm.adConfigData.changshangnew.vivo.randomNativeId
                                .length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addLadderNativeId("vivo")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "200px",
                                "text-align": "right",
                                "padding-right": "12px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                                "flex-shrink": "0"
                              }
                            },
                            [
                              _vm._v(
                                "\n              阶梯原生广告id\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            _vm._l(
                              _vm.adConfigData.changshangnew.vivo
                                .randomNativeId,
                              function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      display: "flex",
                                      "align-items": "center",
                                      margin: "15px 0",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                阶梯权重：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "100px",
                                        margin: "0 5px",
                                        "flex-shrink": "0"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.vivo
                                            .randomNativeId[index].num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew.vivo
                                              .randomNativeId[index],
                                            "num",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.vivo.randomNativeId[index].num"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                原生广告ID：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        placeholder: "多个请用英文逗号“,”隔开"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.vivo
                                            .randomNativeId[index].id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew.vivo
                                              .randomNativeId[index],
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.vivo.randomNativeId[index].id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.adConfigData.changshangnew.vivo
                                      .randomNativeId.length > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delLadderNativeId(
                                                  "vivo",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index ==
                                    _vm.adConfigData.changshangnew.vivo
                                      .randomNativeId.length -
                                      1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLadderNativeId(
                                                  "vivo"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("小米厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定原生广告id" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "多个请用英文逗号“,”隔开"
                                },
                                model: {
                                  value:
                                    _vm.adConfigData.changshangnew.xiaomi
                                      .nativeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.adConfigData.changshangnew.xiaomi,
                                      "nativeId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "adConfigData.changshangnew.xiaomi.nativeId"
                                }
                              }),
                              _vm._v(" "),
                              _vm.adConfigData.changshangnew.xiaomi
                                .randomNativeId.length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addLadderNativeId("xiaomi")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "200px",
                                "text-align": "right",
                                "padding-right": "12px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                                "flex-shrink": "0"
                              }
                            },
                            [
                              _vm._v(
                                "\n              阶梯原生广告id\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            _vm._l(
                              _vm.adConfigData.changshangnew.xiaomi
                                .randomNativeId,
                              function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      display: "flex",
                                      "align-items": "center",
                                      margin: "15px 0",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                阶梯权重：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "100px",
                                        margin: "0 5px",
                                        "flex-shrink": "0"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.xiaomi
                                            .randomNativeId[index].num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew
                                              .xiaomi.randomNativeId[index],
                                            "num",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.xiaomi.randomNativeId[index].num"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                原生广告ID：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        placeholder: "多个请用英文逗号“,”隔开"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.xiaomi
                                            .randomNativeId[index].id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew
                                              .xiaomi.randomNativeId[index],
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.xiaomi.randomNativeId[index].id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.adConfigData.changshangnew.xiaomi
                                      .randomNativeId.length > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delLadderNativeId(
                                                  "xiaomi",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index ==
                                    _vm.adConfigData.changshangnew.xiaomi
                                      .randomNativeId.length -
                                      1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLadderNativeId(
                                                  "xiaomi"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("华为厂商广告")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定原生广告id" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "多个请用英文逗号“,”隔开"
                                },
                                model: {
                                  value:
                                    _vm.adConfigData.changshangnew.huawei
                                      .nativeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.adConfigData.changshangnew.huawei,
                                      "nativeId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "adConfigData.changshangnew.huawei.nativeId"
                                }
                              }),
                              _vm._v(" "),
                              _vm.adConfigData.changshangnew.huawei
                                .randomNativeId.length < 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addLadderNativeId("huawei")
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-start",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                width: "200px",
                                "text-align": "right",
                                "padding-right": "12px",
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                                "flex-shrink": "0"
                              }
                            },
                            [
                              _vm._v(
                                "\n              阶梯原生广告id\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            _vm._l(
                              _vm.adConfigData.changshangnew.huawei
                                .randomNativeId,
                              function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      display: "flex",
                                      "align-items": "center",
                                      margin: "15px 0",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                阶梯权重：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "100px",
                                        margin: "0 5px",
                                        "flex-shrink": "0"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.huawei
                                            .randomNativeId[index].num,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew
                                              .huawei.randomNativeId[index],
                                            "num",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.huawei.randomNativeId[index].num"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                原生广告ID：\n                "
                                    ),
                                    _c("el-input", {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        placeholder: "多个请用英文逗号“,”隔开"
                                      },
                                      model: {
                                        value:
                                          _vm.adConfigData.changshangnew.huawei
                                            .randomNativeId[index].id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.adConfigData.changshangnew
                                              .huawei.randomNativeId[index],
                                            "id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "adConfigData.changshangnew.huawei.randomNativeId[index].id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.adConfigData.changshangnew.huawei
                                      .randomNativeId.length > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delLadderNativeId(
                                                  "huawei",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index ==
                                    _vm.adConfigData.changshangnew.huawei
                                      .randomNativeId.length -
                                      1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px"
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLadderNativeId(
                                                  "huawei"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "web广告",
            visible: _vm.webModal,
            width: "40%",
            "before-close": _vm.hideWebModal
          },
          on: {
            "update:visible": function($event) {
              _vm.webModal = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "webForm",
              attrs: { model: _vm.curWebData, "label-width": "150px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "广告名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.curWebData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.curWebData, "title", $$v)
                      },
                      expression: "curWebData.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "广告图片素材" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload1",
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "http://v1cdn.yunyihudong.com/upload.php",
                        name: "myfile",
                        data: _vm.upload1,
                        accept: "image/jpeg,image/jpg,image/png",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.handlePreview1,
                        "on-success": _vm.uploadSuccess1,
                        "on-error": _vm.uploadError1
                      }
                    },
                    [
                      _vm.fileUrl1
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.fileUrl1 }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "落地页地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.curWebData.targetUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.curWebData, "targetUrl", $$v)
                      },
                      expression: "curWebData.targetUrl"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "投放厂商" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.curWebData.changshang,
                        callback: function($$v) {
                          _vm.$set(_vm.curWebData, "changshang", $$v)
                        },
                        expression: "curWebData.changshang"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "vivo" } }, [
                        _vm._v("vivo")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "xiaomi" } }, [
                        _vm._v("小米")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "oppo" } }, [
                        _vm._v("oppo")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "huawei" } }, [
                        _vm._v("华为")
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "other" } }, [
                        _vm._v("其他")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "广告启用状态状态" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.curWebData.switch,
                      callback: function($$v) {
                        _vm.$set(_vm.curWebData, "switch", $$v)
                      },
                      expression: "curWebData.switch"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.hideWebModal } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitWebAdd } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "域名信息", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formInfo } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "App名称", "label-width": _vm.formLabelWidth }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入App名称",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.formInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "name", $$v)
                      },
                      expression: "formInfo.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "App包名", "label-width": _vm.formLabelWidth }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入App包名",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.formInfo.packageName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "packageName", $$v)
                      },
                      expression: "formInfo.packageName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权重", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入权重", autocomplete: "off" },
                    model: {
                      value: _vm.formInfo.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "weight", $$v)
                      },
                      expression: "formInfo.weight"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上线时间",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间"
                    },
                    model: {
                      value: _vm.formInfo.onlineTime,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "onlineTime", $$v)
                      },
                      expression: "formInfo.onlineTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "下线时间",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间"
                    },
                    model: {
                      value: _vm.formInfo.offlineTime,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "offlineTime", $$v)
                      },
                      expression: "formInfo.offlineTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "点好跳转",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formInfo.okSkipSwitch,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "okSkipSwitch", $$v)
                      },
                      expression: "formInfo.okSkipSwitch"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formInfo.switch,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "switch", $$v)
                      },
                      expression: "formInfo.switch"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加链接", visible: _vm.addLinkVisible },
          on: {
            "update:visible": function($event) {
              _vm.addLinkVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.linkForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "广告名称",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入广告名称",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.linkForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.linkForm, "title", $$v)
                      },
                      expression: "linkForm.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "拉起链接",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入拉起链接",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.linkForm.url,
                      callback: function($$v) {
                        _vm.$set(_vm.linkForm, "url", $$v)
                      },
                      expression: "linkForm.url"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "素材地址",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload2",
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "http://v1cdn.yunyihudong.com/upload.php",
                        name: "myfile",
                        data: _vm.upload2,
                        accept: "image/jpeg,image/jpg,image/png",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.handlePreview2,
                        "on-success": _vm.uploadSuccess2,
                        "on-error": _vm.uploadError2
                      }
                    },
                    [
                      _vm.fileUrl2
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.fileUrl2 }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addLinkVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onAddLinkConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }