"use strict";

var _interopRequireDefault = require("/Users/mac/Desktop/quike/\u8FD0\u8425\u914D\u7F6E\u540E\u53F0/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.fetchAdd = fetchAdd;
exports.fetchUpdate = fetchUpdate;
exports.fetchDel = fetchDel;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取小程序校验文件列表
function fetchList(data) {
  return (0, _request.default)({
    url: '/Wxconfig/listsfile',
    method: 'post',
    data: data
  });
} // 添加小程序校验文件


function fetchAdd(data) {
  return (0, _request.default)({
    url: '/Wxconfig/addfile',
    method: 'post',
    data: data
  });
} // 添加自定义事件


function fetchUpdate(data) {
  return (0, _request.default)({
    url: '/pkginfo/update',
    method: 'post',
    data: data
  });
} // 删除文件


function fetchDel(data) {
  return (0, _request.default)({
    url: '/Wxconfig/delfile',
    method: 'post',
    data: data
  });
}