var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.globalInfo
        ? _c(
            "div",
            { staticClass: "search-box" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.updateConfig()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v("指定链接ID活动页使用正常后退弹窗")]),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("指定的链接id")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.globalInfo.normalLeavePopId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.globalInfo,
                                  "normalLeavePopId",
                                  $$v
                                )
                              },
                              expression: "globalInfo.normalLeavePopId"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("多个链接id请用英文逗号‘,’隔开")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticStyle: { "margin-top": "50px" } }, [
        _vm._v("指定链接ID时光柚隐藏宜忌和星座运势")
      ]),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px",
                "margin-bottom": "20px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { height: "40px" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "row_style" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my_label",
                          staticStyle: { width: "120px" }
                        },
                        [_vm._v("指定的链接id")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { flex: "1", "padding-right": "20px" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.globalInfo.toutiaoId,
                              callback: function($$v) {
                                _vm.$set(_vm.globalInfo, "toutiaoId", $$v)
                              },
                              expression: "globalInfo.toutiaoId"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "my_describ" }, [
                      _vm._v("多个链接id请用英文逗号‘,’隔开")
                    ])
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticStyle: { "margin-top": "50px" } }, [
        _vm._v("指定链接ID一进入活动页直接跳转指定网址")
      ]),
      _vm._v(" "),
      _vm.globalInfo
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "20px 20px 0 20px",
                "margin-bottom": "20px"
              }
            },
            [
              _vm.globalInfo.activityControl.directJump.length < 1
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addLinkId()
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.globalInfo.activityControl.directJump, function(
                item,
                index
              ) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticStyle: { height: "60px" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "row_style" }, [
                          _c(
                            "div",
                            {
                              staticClass: "my_label",
                              staticStyle: { width: "120px" }
                            },
                            [_vm._v("指定的链接id")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "1",
                                "padding-right": "20px"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.globalInfo.activityControl.directJump[
                                      index
                                    ].linkId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.globalInfo.activityControl.directJump[
                                        index
                                      ],
                                      "linkId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "globalInfo.activityControl.directJump[index].linkId"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "my_describ" }, [
                          _vm._v("多个链接id请用英文逗号‘,’隔开")
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { height: "60px" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "row_style" }, [
                          _c(
                            "div",
                            {
                              staticClass: "my_label",
                              staticStyle: { width: "120px" }
                            },
                            [_vm._v("跳转地址")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "1",
                                "padding-right": "20px"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.globalInfo.activityControl.directJump[
                                      index
                                    ].activityUrl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.globalInfo.activityControl.directJump[
                                        index
                                      ],
                                      "activityUrl",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "globalInfo.activityControl.directJump[index].activityUrl"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "my_describ" }, [
                            _vm._v(
                              "链接id如果匹配，一进入活动页将会直接跳转到该网址"
                            )
                          ]),
                          _vm._v(" "),
                          _vm.globalInfo.activityControl.directJump.length > 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delLinkId(index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index ==
                          _vm.globalInfo.activityControl.directJump.length - 1
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addLinkId()
                                    }
                                  }
                                },
                                [_vm._v("添加")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }