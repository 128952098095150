"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("@/api/appadmin/index");

var _elementUi = require("element-ui");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'userAgreement',
  data: function data() {
    return {
      globalInfo: null,
      timer: null
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;

    this.getGlobalSetting();
    this.timer = setInterval(function () {
      (0, _index.fetchUpdate)().then(function (r) {
        if (!r.DATA) {
          _this.getGlobalSetting();
        }
      });
    }, 2000);
  },
  filters: {},
  methods: {
    getGlobalSetting: function getGlobalSetting() {
      var _this2 = this;

      (0, _index.getGlobalSetting)().then(function (r) {
        _this2.globalInfo = r.DATA ? JSON.parse(unescape(r.DATA)) : null;
      });
    },
    updateConfig: function updateConfig() {
      var _this3 = this;

      (0, _index.setGlobalSetting)({
        jsonData: escape(JSON.stringify(this.globalInfo))
      }).then(function (r) {
        _this3.getGlobalSetting();

        (0, _elementUi.Message)({
          type: "success",
          message: "操作成功"
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  }
};
exports.default = _default;