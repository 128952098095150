"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Vue Admin Template',
      logo: require("../../../assets/tab.png"),
      logo_mini: require("../../../assets/tab_mini.png")
    };
  }
};
exports.default = _default;